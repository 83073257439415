import React from 'react';
import {Tabs, Tab} from 'react-bootstrap'
import ContainerHeader from '../components/common/ContainerHeader'
import details from '../utils/details'
import sitedata from '../utils/sitedata'
import PageWrapper from "../components/common/PageWrapper"
import DataDetailsContainer from '../components/containers/DataDetailsContainer'
import DataLinkContainer from '../components/containers/DataLinkContainer'
import DataManageContainer from '../components/containers/DataManageContainer'
import DataPublishingContainer from '../components/containers/DataPublishingContainer'
import DataSummaryContainer from '../components/containers/DataSummaryContainer'
import DataReferencesContainer from '../components/containers/DataReferencesContainer'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import TabCheck from "../components/common/TabCheck"
import Spinner from "../components/common/Spinner"
import NewObjectButton from '../components/common/NewObjectButton'
import DataDetailsReadOnly from "./DataDetailsReadOnly";
import {withTranslation} from "react-i18next";
import envid from '../utils/envid';

/**
 * Provides the view of a data object.
 *
 * params
 *   tabName
 *   objectId
 *
 * props
 *   currentObject
 *   savedStatus
 *   dimensions
 *   sitedata
 *   callbacks
 *      - updateObject
 *      - updateSavedStatus
 *      - clickedObject
 *      - clickedTab
 *      - any the children use, which i will have to go through later
 */
class DataObjectView extends React.Component {

    formatDate(d)
    {
        var dt = new Date(d);
        return dt.toLocaleDateString();
    }



    render() {

        // console.log('DataObjectView::',this.props);
        const {t} = this.props;

        try {
            let params = sitedata.getParams(this.props);
            let object_id = params.objectId;
            let object = this.props.currentObject;

            if (object == null || object.object.object_id !== object_id) {
                // ask the parent to load the object
                this.props.callbacks.loadObject(object_id);
                return(
                    <div>
                        <ContainerHeader dimensions={this.props.dimensions} />
                        <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                            <Spinner/>
                        </PageWrapper>
                    </div>
                );
            }

            let type = this.props.currentObject.object.type;
            let typeName = this.props.currentObject.object.type;
            //const title = "".concat(this.props.currentObject.type," - ",this.props.currentObject.details.name);
            //<EnvidObjectIcon type={typeName} showName={false} style={{marginRight:"10px", fontSize:'large', display:'inline-block'}}/>
            var title = (<span>{this.props.currentObject.object.details.name}</span>);
            if(type === "Reference"){
                title = (<span>{t("Reference")}</span>);
            }
            var subtitle = "";

            if (this.props.savedStatus === "unchanged") {
                subtitle = t("Last Saved") + " " + this.formatDate(this.props.currentObject.object.documentInfo.last_update_time);
            }
            else if (this.props.savedStatus === "saved") {
                subtitle = t("All Changes Saved");
            }
            else if (this.props.savedStatus === "saving") {
                subtitle = t("Saving") + "...";
            }
            else {
                subtitle = t("Saving") + "...";
            }

            subtitle = "(" + subtitle + ")";

            let publishState = this.props.currentObject.object.publishingInfo.state;
            let publishColour = envid.getPublishStateColour(publishState);

            //console.log("PUBLISH STATE TAB RENDER ", publishState);

            var publishing_title = <span><i className="fa fa-circle" style={{color: publishColour}}/>{t("Publishing")}</span>

            let last_crumb = (<EnvidObjectIcon type={typeName} showName={true}/>);
            let template = details.getObjectTemplate(type);
            if(this.props.readOnly){
                template = details.getObjectReadOnlyTemplate(type);
            }

            var content_style = {};
            content_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height - 70;
            content_style.overflow = "scroll";

            var link_content_style = {};
            link_content_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height - 70;
            link_content_style.overflow = "hidden";

            var tabs = [];
            for (var i in template) {
                let t = template[i];
                let title = t.tabName;
//<DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>
                var content = null;
                if (t.tabType === "det") {
                    content = <div className="ibox-content" style={content_style}>
                        {this.props.readOnly ?
                            <DataDetailsReadOnly fields={t.fields} currentObject={this.props.currentObject}/> :
                            <DataDetailsContainer siteData={this.props.siteData}
                                                  updateObject={this.props.callbacks.updateObject}
                                                  updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                                  currentObject={this.props.currentObject}
                                                  fields={t.fields}/>
                        }
                    </div>

                } else if (t.tabType === "sum") {
                    content = <div className="ibox-content" style={content_style}>
                        <DataSummaryContainer currentObject={this.props.currentObject}
                                              siteData={this.props.siteData}
                                              callbacks={this.props.callbacks}
                        />
                    </div>

                } else if (t.tabType === "pub") {
                    content = <div className="ibox-content" style={content_style}>
                        <DataPublishingContainer currentObject={this.props.currentObject}
                                                 updateObject={this.props.callbacks.updateObject}
                                                 siteData={this.props.siteData}
                                                 updateSavedStatus={this.props.callbacks.updateSavedStatus}
                        />
                    </div>

                    title = publishing_title;
                } else if (t.tabType === "ref") {
                    content = <div className="ibox-content" style={content_style}>
                            <DataReferencesContainer currentObject={this.props.currentObject}
                                                   updateObject={this.props.callbacks.updateObject}
                                                   updateSavedStatus={this.props.callbacks.updateSavedStatus}
                />
                </div>

                } else if (t.tabType === "link" || t.tabType === "link-1" || t.tabType === "link-2") {
                    content = <div className="ibox-content" style={link_content_style}>
                        <DataLinkContainer siteData={this.props.siteData}
                                           updateObject={this.props.callbacks.updateObject}
                                           updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                           addObject={this.props.callbacks.add}
                                           currentObject={this.props.currentObject}
                                           template={t}
                                           changeTime={this.props.changeTime}
                                           changeLinkTime={this.props.changeLinkTime}
                                           height={content_style.height}
                        />
                    </div>

                } else if (t.tabType === "man") {
                    content = <div className="ibox-content" style={link_content_style}>
                        <DataManageContainer siteData={this.props.siteData}
                                           updateObject={this.props.callbacks.updateObject}
                                           updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                           addObject={this.props.callbacks.add}
                                           currentObject={this.props.currentObject}
                                           template={t}
                                           height={content_style.height}
                        />
                    </div>

                } else {
                    content = <div>ERROR</div>
                }

                tabs.push((
                    <Tab key={t.tabType} eventKey={t.tabType} title={title}>
                        <TabCheck eventKey={t.tabType} activeKey={params.tabName}>
                            {content}
                        </TabCheck>
                    </Tab>
                ));

            }

            const addButton = <NewObjectButton onAddSelect={this.props.callbacks.add}/>;

            return (
                <div>
                    <ContainerHeader title={title}
                                     subtitle={subtitle}
                                     crumbs={['/', '/enviddata', last_crumb]}
                                     rightComponent={this.props.readOnly ? null : addButton}
                                     dimensions={this.props.dimensions}
                    />
                    <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                        <div className="tabs-container">
                            <Tabs id="object-tabs" activeKey={params.tabName} onSelect={this.props.callbacks.clickedTab}>
                                {tabs}
                            </Tabs>
                        </div>
                    </PageWrapper>
                </div>
            )
        } catch (e)
        {
            console.log(e);
            return <div>{t("Caught Exception")}</div>
        }
    }

}

export default withTranslation()(DataObjectView)
