import React from 'react';
import {Row,Col,FormControl, ControlLabel} from 'react-bootstrap'

import EButton from "../components/common/EButton"
import envid from "../utils/envid"
import {ComplianceLabel} from '../components/common/ComplianceUtils'
import EnvidDate from '../utils/EnvidDate'
import {ToastContainer, ToastMessage} from 'react-toastr'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Trans, withTranslation} from "react-i18next"; // Import css

var ToastMessageFactory = React.createFactory(ToastMessage.animation);

class ActionsView extends React.Component {

    constructor() {
        super();


        this.uploadEvidence = this.uploadEvidence.bind(this);
        this.showLoadError = this.showLoadError.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.deleteEvidence = this.deleteEvidence.bind(this);
    }

    finishedField(fieldName, event){
        this.props.saveFields();
    }

    inputChanged(object_id, fieldName, event){
        event.preventDefault();
        let value = event.target.value;
        this.props.updateField(object_id, fieldName, value);
    }

    selectChanged(object_id, fieldName, event){
        let value = event.target.value;

        this.props.updateField(object_id, fieldName, value);

        setTimeout(this.props.saveFields, 250);
    }

    deleteAction(action_id)
    {
        const {t} = this.props;

        confirmAlert({customUI:({onClose}) => <div className="panel panel-info">
                                                <div className="panel-heading">
                                                    <i className="fa fa-info-circle"></i> Confirm
                                                </div>
                                                <div className="panel-body">
                                                    <p><Trans i18nKey="delete-compliance">Are you sure you want to delete this Compliance Action?</Trans></p>

                                                    <Row>
                                                        <Col md={12} style={{textAlign:"right"}}>
                                                            <EButton type="standard" onClick={()=>{onClose();}}>{t("Cancel")}</EButton>
                                                            &nbsp;&nbsp;
                                                            <EButton type="delete" onClick={()=>{
                                                                this.props.deleteAction(action_id);
                                                                onClose();}}>{t("Delete")}</EButton>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>})
    }
    uploadEvidence(action_id, event)
    {
        const {t} = this.props;

        if (event.target.files.length > 0){

            let file = event.target.files[0];
            if (file.type !== "application/pdf")
            {
                this.showLoadError(t("Bad File"), t("Only PDF files are accepted"));
                return;
            }
            else
            {
                this.props.uploadEvidence(action_id, file);
            }

        }

    }

    deleteEvidence(action_id,path){
        this.props.deleteEvidence(action_id,path);
    }

    showLoadError(title, message)
    {

        let error_message = message;

        this.refs.loaderror.error(error_message, title, {
            timeOut: 2000,
            extendedTimeOut: 0
        });
    }

    render() {

        const {t} = this.props;

        let actions = this.props.actions;
        let actionItems = [];

        if (actions == null || actions.length === 0) {
            return <div style={{textAlign: "center", fontSize: '1.5em'}}>{t("Add your first Action")}</div>
        }

        for (let i = 0; i < actions.length; ++i) {
            let action = actions[i];
            let editing_id = this.props.editing_id;

            let edit = this.props.onEditButton;

            let user_id = action.documentInfo.author;
            let date = envid.getObjectField(action, "actioned_date");

            // look up this persons name
            let desc = envid.getObjectField(action, "name");
            let status = envid.getObjectField(action, "status");
            let user_name = envid.getObjectField(this.props.users[user_id], "name");
            let due_index = envid.getObjectField(action, "due_index");
            // console.log("DUE INDEX = " + due_index);
            // console.log(action);

            let actioned_date = action.details.actioned_date;
            date = new EnvidDate(actioned_date, t);

            let style = {background: "#1c84c6", color: "white"};
            let icon = "fa-question";

            if (status === "COMPLIANT") {
                icon = "fa-check";
                style = {background: "#1ab394", color: "white"};
            }
            else if (status === "NOT_COMPLIANT") {
                icon = "fa-warning";
                style = {background: "#ed5565", color: "white"};

            }
            else if (status === "IN_PROGRESS") {
                icon = "fa-clock-o";
                style = {background: "#f8ac59", color: "white"};

            }

            let isEditing = (editing_id === action.object_id && i===0);

            let evidence = (isEditing) ? null : <span>{t('None')}</span>
            if (action.details.evidence_paths != null && action.details.evidence_paths.length > 0)
            {
                evidence = action.details.evidence_paths.map( (path) => {
                    let splt = path.split("/");
                    let filename = splt[splt.length-1];
                    if (path.indexOf("/uploading")===0)
                    {
                        return <span><i className="fa fa-file-pdf-o"></i> {filename} <span className="label label-success">{t("Uploading")}</span><br/></span>
                    }
                    else if (path.indexOf("/error")===0)
                    {
                        return <span><i className="fa fa-file-pdf-o"></i> {filename} <span className="label label-danger">{t("Error")}</span><br/></span>
                    }
                    else
                    {
                        if(isEditing) {

                            let deleting = path.startsWith("/deleting");

                            return <span><i className="fa fa-file-pdf-o"></i> <a
                                href={"/ebase/compliance/read-compliance-evidence" + path}
                                target="_blank" rel="noopener noreferrer">{filename}</a>&nbsp;&nbsp;&nbsp;<EButton ebSize="xs" type="remove" isTable={true} disabled={deleting} onClick={() => {this.deleteEvidence(action.object_id,path)}}/><br/></span>
                        }else{
                            return <span><i className="fa fa-file-pdf-o"></i> <a
                                href={"/ebase/compliance/read-compliance-evidence" + path}
                                target="_blank" rel="noopener noreferrer">{filename}</a><br/></span>
                        }
                    }
                })
            }

            let item_left = (
                <Col xs={3} className="date">
                    <i className={" fa " + icon} style={style}></i>
                    {date.format("D MMM YYYY")}
                    <br/>
                    <small className="text-navy">{date.fromNow()}</small>
                </Col>
            );

            let item_right = null;

            if (isEditing) {

                let due_dates = [];
                let past_due_dates = this.props.complianceDefn.details.past_due_dates;
                for (let i=past_due_dates.length-1; i>=0; --i)
                {
                    let d = new EnvidDate(past_due_dates[i], t);
                    let current = (i===past_due_dates.length-1) ? " (Current)" : null;

                    console.log("Adding Due Date : " + (""+i));
                    console.log("Adding Due Date : " + d.format("DD/MM/YYYY"));
                    due_dates.push(<option value={""+i}>{d.format("DD/MM/YYYY")}{current}</option>);
                }

                item_right = (
                <Col xs={7} className="content" style={{background: "#FAFAFA"}}>

                    <Row style={{marginTop: 10, marginBottom: 10}}>
                        <Col xs={3}>
                            <ControlLabel>{t("Actioned Date")}</ControlLabel>
                        </Col>
                        <Col xs={5}>
                            <FormControl componentClass="input"
                                         type="date"
                                         placeholder="select"
                                         value={date.format("YYYY-MM-DD")}
                                         onChange={this.selectChanged.bind(this, action.object_id, "details.actioned_date")}>
                            </FormControl>
                        </Col>
                        <Col md={4} mdPush={0} className="pull-right" style={{textAlign:"right"}}>
                            <strong><i className="fa fa-user-circle-o"></i> {user_name}</strong>
                        </Col>


                    </Row>

                    <Row style={{marginTop: 10, marginBottom: 10}}>
                        <Col xs={3}>
                            <ControlLabel>{t('Note')}</ControlLabel>
                        </Col>
                        <Col xs={8}>
                            <FormControl componentClass="textarea"
                                         placeholder="select"
                                         onChange={this.inputChanged.bind(this, action.object_id, "details.name")}
                                         onBlur={this.finishedField.bind(this, action.object_id, "details.name")}
                                         value={desc}>
                            </FormControl>
                        </Col>
                    </Row>


                    <Row style={{marginTop: 0, marginBottom: 10}}>
                        <Col md="3"><ControlLabel>{t('Evidence')}</ControlLabel></Col>
                        <Col md="9">
                            {evidence}
                            {(evidence==null)?null:<br/>}
                            <input type="file" ref="uploadFileRef"
                                   style={{display:"none"}}
                                   onChange={this.uploadEvidence.bind(this, action.object_id)}
                            />

                            <EButton isTable={true} type="upload" onClick={() => {this.refs.uploadFileRef.click()}}>{t("Upload Evidence PDF")}</EButton>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3}>
                            <ControlLabel>{t("Status When Actioned")}</ControlLabel>
                        </Col>
                        <Col xs={5}>
                            <FormControl componentClass="select" placeholder="select"
                                         value={status}
                                         onChange={this.selectChanged.bind(this, action.object_id, "details.status")}>
                                <option value="COMPLIANT">{t("Compliant")}</option>
                                <option value="NOT_COMPLIANT">{t("Non Compliant")}</option>
                                <option value="IN_PROGRESS">{t("In Progress")}</option>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3}>
                            <ControlLabel>{t("Applies to Due Date")}</ControlLabel>
                        </Col>
                        <Col xs={5}>
                            <FormControl componentClass="select" placeholder="select"
                                         value={due_index}
                                         onChange={this.selectChanged.bind(this, action.object_id, "details.due_index")}>

                                {due_dates}
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{textAlign:"right"}}>
                            <EButton isTable={true} type="standard" onClick={this.deleteAction.bind(this, action.object_id)}>{t('Delete')}</EButton>
                            &nbsp;&nbsp;
                            <EButton isTable={true} type="done" onClick={() => edit(null)}>{t('Done')}</EButton>
                        </Col>
                    </Row>
                </Col>
                );

            }
            else {

                item_right = (
                            <Col xs="7" className={"content " + ((i === 0) ? "no-top-border" : "")}>
                                <Row style={{marginTop: -7}}>
                                    <Col md={4}>
                                        <ComplianceLabel status={status}/>
                                    </Col>
                                    <Col md={6} className="pull-right" style={{textAlign:"right"}}>
                                        <strong><i className="fa fa-user-circle-o"></i> {user_name}</strong>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: 10, marginBottom: 10}}>
                                    <Col md={2}><b>{t('Note')}</b></Col>
                                    <Col md={10}>
                                        {desc.split("\n").map((item) => {
                                            return <p>{item}</p>
                                            })
                                        }
                                    </Col>
                                </Row>

                                <Row style={{marginTop: 0, marginBottom: 10}}>
                                    <Col md={2}><b>{t('Evidence')}</b></Col>
                                    <Col md={8}>
                                        {evidence}
                                    </Col>
                                    <Col md={2} style={{position:"relative", width:"100%", height:"100%"}}>
                                        <span style={{position:"absolute", bottom:-5, right:10}}>
                                        <EButton isTable={true} type="standard" onClick={this.deleteAction.bind(this, action.object_id)}>{t("Delete")}</EButton>
                                        </span>
                                    </Col>
                                </Row>


                            </Col>
                );

            }
            actionItems.push(
                <Row className="timeline-item">
                    <Row>
                        {item_left}
                        {item_right}
                    </Row>
                </Row>
            );

        }

        return (
            <div className="ibox-content inspinia-timeline">

                <ToastContainer ref="loaderror" toastMessageFactory={ToastMessageFactory}/>

                {actionItems}

            </div>
        )
    }
}

export default withTranslation()(ActionsView)
