/**
 * Created by lewellyn on 7/9/18.
 */
import React from 'react';
import { Dropdown,MenuItem } from 'react-bootstrap';

class EBDropDownToggle extends React.Component{
    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(e){
        e.preventDefault();
        this.props.onClick(e);
    }

    render() {

        return (

            <a href="" onClick={this.handleClick}>
                {this.props.children}
            </a>

        );
    }
}


/*

Made this to be used in the navigation menu for dataset selector. So it's a bit limited. Can develop it futher if we need
more out of it later on.

props:
    - options:
        list option with objects: {key: <string> , title: <string>}
    - title:
        String that will be the text user sees to click to show menu
    - onSelect
        function, passes event key to onSelect handler.
 */

class EBDropDown extends React.Component {

    constructor(props){
        super(props);

        this.optionSelect = this.optionSelect.bind(this);
    }

    optionSelect(eventKey, event){

        //console.log("EBDropdown optionselect");
        //console.log(eventKey);
        //console.log(event);

        this.props.onSelect(eventKey);

    }

    render() {


        let items = [];

        for(var i=0;i<this.props.options.length;i++){

            let info = this.props.options[i];

            items.push(<MenuItem key={info.key} active={this.props.activeKey === info.key} eventKey={info.key}>{info.title}</MenuItem>)
        }



        return (

            <Dropdown id="ebdropdown-custom" onSelect={this.optionSelect}>

                <EBDropDownToggle bsRole="toggle">{this.props.title}&nbsp;<b className="caret"></b></EBDropDownToggle>

                <Dropdown.Menu>
                    {items}
                </Dropdown.Menu>

            </Dropdown>



        );
    }

}


export default EBDropDown
