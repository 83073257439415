/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import { connect } from 'react-redux'
import {loadReportDone, loadReportStart} from "../../redux/actions/actions";
import StakeholderMgrContainer from "./StakeholderMgrContainer";
import StakeholderCommsContainer from "./StakeholderCommsContainer";
import StakeholderHomeView from "../../views/StakeholderHomeView";

const reportType = "Stakeholder";

const loadReport = function(project_id, dispatch, ownProps) {

    dispatch(loadReportStart());
    ebase.ajax({
        url: "/ebase/envid-client/read-report-load",
        data: {project_id: project_id, report_type: reportType},
        success: (result) => {
            if (result.success) {
                dispatch(loadReportDone(result))
            } else {
                ownProps.showLoadError("Error Loading Project", result);
            }
        },
        error: (result) => {
            ownProps.showLoadError("Error Loading Project", result);
        }
    })

};

const mapStateToProps = (state, ownProps) =>
{

    let currentReport = state.reports.currentObject;

    return {
        report: (currentReport==null)? null : currentReport,
        project: (currentReport==null)? null : currentReport.project,
        isLoading: state.reports.isLoading,
        dimensions:state.session.dimensions,
        readOnly:(!sitedata.checkWriteAccess(state.session.siteData,"project"))
    }
}



const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadReport : (project_id) => loadReport(project_id, dispatch, ownProps),
        makeTitle(project, page_name)
        {
            if (project == null) return page_name;
            return project.name + ": " + page_name;
        },



    }
}

class ReportChildrenView extends React.Component {
    render() {
        // console.log("StakeholderHomeContainer render");
        // console.log(this.props);
        // console.log("");

        let path = this.props.location.pathname;

        if (/stakeholder\/[^\/]*\/(home|objections|actions)/.test(path)){
            // console.log("StakeholderHomeContainer - StakeholderHomeView ",this.props);
            return (
                <div>
                    {React.cloneElement(<StakeholderHomeView />, this.props)}
                </div>
            )
        }
        if (/stakeholder\/.*\/manage/.test(path)){
            // console.log("StakeholderHomeContainer - StakeholderMgrContainer ",this.props);
            return (
                <div>
                    {React.cloneElement(<StakeholderMgrContainer />, this.props)}
                </div>
            )
        }
        if (/stakeholder\/.*\/comms/.test(path)){
            // console.log("StakeholderHomeContainer - StakeholderCommsContainer ",this.props);
            return (
                <div>
                    {React.cloneElement(<StakeholderCommsContainer />, this.props)}
                </div>
            )
        }

        return (
            <div>
                -_-
            </div>
        )

    }

    componentWillReceiveProps(nextProps)
    {

        // if (nextProps.unsavedAnswers != null)
        // {
        //     this.props.saveAnswers(nextProps.unsavedAnswers);
        // }

        let nextParams = sitedata.getParams(nextProps);
        let params = sitedata.getParams(this.props);

        if(nextParams.projectId !== params.projectId){
            this.props.loadReport(nextParams.projectId);
        }


    }

    componentWillMount()
    {
        let params = sitedata.getParams(this.props);
        let object_id = params.projectId;
        this.props.loadReport(object_id);
    }


};

const StakeholderHomeContainer = connect(mapStateToProps, mapDispatchToProps)(ReportChildrenView)

export default StakeholderHomeContainer
