import React from 'react';
import {Grid, Row, Col, Panel, Button} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import {Trans, withTranslation} from "react-i18next";

class Login extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {pending:false, username:null, password:null,showAccountHelp:false};

        this.userChanged = this.userChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.try_login = this.try_login.bind(this);

        this.loginField = HTMLInputElement;
    }

    userChanged(event) {
        this.setState({username: event.target.value});
    }

    passwordChanged(event) {
        this.setState({password: event.target.value});
    }

    try_login()
    {
        this.setState({pending : true});
        this.props.onLogin(this.state.username, '');

    }

    componentDidMount() {
        // console.log("LiteLogin:: componentDidMount ",this.props);
        if (this.loginField != null && !this.props.isAuthenticated) {
            this.loginField.focus();
        }
    }

    render() {

        const {t} = this.props;

        // console.log("LiteLogin:: ",this.props);

        if (this.props.isAuthenticated){
            return <Redirect to="/impactsummary" />;
        }

        let isLoading = this.state.pending && this.props.error==null;

        let error = null;
        if (this.props.error != null)
        {
            error = (
                <div><span className="label label-warning">{this.props.error}</span><br/><br/></div>
            )


        }

        var forgotPassword = "";
        var createAccount = "";
        var createAccountButton = "";

        if(this.state.showAccountHelp){
            forgotPassword = (<a href="#">
                <small>{t("Forgot password?")}</small>
            </a>);
            createAccount = (<p className="text-muted text-center">
                <small>{t("Do not have an account?")}</small>
            </p>);
            createAccountButton = <Button className="white block" href="register.html"> {t("Create an account")} </Button>;

        }

        return (
            <div className="ebase-litelogin">
                <Grid className="gray-bg loginColumns animated fadeInDown" style={{paddingTop:0}}>
                    <Row style={{display:'flex', justifyContent:'flex-end', marginRight:0, height:86}}>
                            <img alt="Banner" src='img/banner-flag.png' width={76}/>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <img alt={"Ebase Lite Logo"} src={"img/ebase-lite-logo-dark.png"} width={250} style={{marginBottom:-20}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Trans i18nKey="login-lite-desc">
                                <p style={{marginTop:25}}>To access the eBase Lite Australian Oil and Gas Knowledge Library, enter your email address</p>
                                <p>Click <a target="_blank" rel="noopener noreferrer" href="https://www.ebase.com.au">here</a> from more information on eBase.</p>
                            </Trans>
                        </Col>
                        <Col md={6}>
                            <Panel className="m-t">

                                {error}
                                <form>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder={t("Your Email")} required=""
                                               onChange={this.userChanged} ref={r => this.loginField = r} />
                                    </div>

                                    <Button type="submit" bsStyle="primary" style={{backgroundColor:'#2b3845', borderColor: '#2b3845'}} className="full-width m-b"
                                            onClick={this.try_login} disabled={isLoading} onSubmit={this.try_login}>
                                        {isLoading ? t("Working") : t("Let's Go")}
                                    </Button>
                                </form>
                                {forgotPassword}
                                {createAccount}
                                {createAccountButton}
                            </Panel>
                        </Col>
                    </Row>

                    <hr/>
                    <div className="row">
                        <div className="col-md-6">
                            {t("Copyright Xodus Green Light Patented AU2017100353")}
                        </div>
                        <div className="col-md-6 text-right">
                            <small>{t("© 2020")}</small>
                        </div>
                    </div>

                </Grid>
            </div>
        )

    }

}

export default withTranslation()(Login)
