/**
 * Created by lewellyn on 28/9/18.
 */
import React, {useEffect, useRef, useState} from 'react';
import {
  FormControl,
  FormGroup,
  ControlLabel,
} from 'react-bootstrap';
import 'icheck/skins/all.css'
import {Checkbox} from 'react-icheck';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import EButton from '../components/common/EButton';
import Spinner from '../components/common/Spinner';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SessionManager from '../utils/SessionManager';
import EBDropDown from '../components/common/EBDropDown';
import {TemplateManager} from '../utils/TemplateManager';

const ReportTaskTypes = [
  'AspectActivity',
  'Receptor',
  'Impact',
  'Control',
  'Risk',
  'EnvironmentalInputs',
  'TechnicalInputs',

  // Control Summary Tasks
  'Activity',

  // Compliance Tasks
  'Compliance',

  // Stakeholder tasks
  'Stakeholder',
];

const TemplateEditView = (props) => {

  const {t} = useTranslation();

  const templates = useSelector((state) => state.templates.templates);
  const dimensions = useSelector((state) => state.session.dimensions);

  const [template, setTemplate] = useState(
    {name: '', description: '', report_type: '', type: '', requiredTasks: [], default: false});
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [templateFile, setTemplateFile] = useState();

  const uploadFileRef = useRef(null);

  const DROPDOWNS = {
    report_type: {
      fieldName: 'report_type',
      title: t('Select Report Type'),
      options: [
        {title: t('Impact Assessment'), key: 'ImpactAssessment'},
        {title: t('Controls Summary'), key: 'ControlsSummary'},
        {title: t('Compliance'), key: 'Compliance'},
        {title: t('Stakeholder'), key: 'Stakeholder'},
        {title: t('Region Stakeholder'), key: 'RegionStakeholder'},
      ],
    },
    template_type: {
      fieldName: 'type',
      title: t('Select Template Type'),
      options: [
        {title: t('Word'), key: 'WORD_DOCX'},
        {title: t('Excel'), key: 'EXCEL_XLSX'},
        {title: t('CSV'), key: 'CSV'},
        {title: t('ZIP'), key: 'ZIP'},
      ],
    },
  };

  const TemplateDropdown = (props) => {
    let active = !!template && props.dataset.options.find((option) => {
      return option.key === template[props.dataset.fieldName];
    });
    return (
      <EBDropDown id={props.id}
                  title={!!active ? active.title : props.dataset.title}
                  options={props.dataset.options}
                  activeKey={active?.key}
                  onSelect={(val) => {
                    updateTemplateFieldValue(props.dataset.fieldName, val);
                  }}>
      </EBDropDown>
    );
  };

  useEffect(() => {
    if (!templates) {
      if (!loading) {
        setLoading(true);
        TemplateManager.loadReportTemplates();
      }
    } else {
      setLoading(false);
    }
  }, [templates, loading]);

  useEffect(() => {
    let templateId = props.match?.params?.template_id;
    let newTemplate;

    if (templateId === 'new') {
      templateId = undefined;
    }

    if (templateId && !!templates &&
      (!template || template.template_id !== templateId)) {
      newTemplate = templates.find(t => t.template_id === templateId);
      if (!newTemplate.requiredTasks) {
        newTemplate.requiredTasks = [];
      }
    }


    if (!!newTemplate) {
      setTemplate(newTemplate);
    }
  }, [templates]);

  useEffect(() => {
    setCanUpdate(
      isTemplateFieldValid('name') &&
      isTemplateFieldValid('report_type') &&
      isTemplateFieldValid('type') &&
      isTemplateFieldValid('description') &&
      (!!template?.template_id || !!templateFile));
  }, [template, templateFile]);

  const reportFileChanged = (event) => {
    if (event?.target?.files?.length) {
      let file = event.target.files[0];
      setTemplateFile(file);
    }
  };

  const downloadTemplate = () => {
    let url =  TemplateManager.downloadTemplate(template.template_id);
    let link = document.createElement("a");
    link.href = url;
    link.click();
  }

  const isRequiredTask = (task) => {
    return !!template.requiredTasks.find(t => t === task);
  }

  const setRequiredTask = (task, on) => {
    const newTemplate = {...template};
    newTemplate.requiredTasks = newTemplate.requiredTasks.filter(t => t !== task);
    if (on) {
      newTemplate.requiredTasks.push(task);
    }
    setTemplate(newTemplate);
  }

  const updateTemplateField = (fieldName, event) => {
    updateTemplateFieldValue(fieldName, event.target.value);
  };

  const updateTemplateCheckField = (fieldName, checked) => {
    updateTemplateFieldValue(fieldName, checked);
  };

  const updateTemplateFieldValue = (fieldName, val) => {
    let newTemplate = {...template};
    newTemplate[fieldName] = val;
    setTemplate(newTemplate);
  };

  const isTemplateFieldValid = (fieldName) => {
    if (fieldName === 'templateFile') {
      return !!template?.template_id || !!templateFile  ? 'success' : null;
    }
    return !!template[fieldName] ? 'success' : null;
  };

  const saveTemplate = () => {
    setSaving(true);
    TemplateManager.updateTemplate(template, templateFile).then(()=>{
      setUpdated(true);
    }).catch((error)=>{
      SessionManager.shared().errorHandler(t('Error Saving Template') +" - " + error, error);
    }).finally(() => {
      setSaving(false);
    });
  };

  const fieldWidth = '65%';

  let content;
  let isNew = !template?.template_id;

  if (saving || loading) {
    content = <Spinner/>;
  } else if (updated) {

    content = (
      <div>
        <div>
          <h3>{t(isNew
            ? 'Template Created Successfully'
            : 'Template Updated Successfully')}</h3>
        </div>
        <div style={{
          width: 300,
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
          <EButton onClick={() => {SessionManager.shared().goto('/templates');}}
                   type="done">
            {t('Done')}
          </EButton>
        </div>
      </div>
    );

  } else {

    let clientInput = (
      <FormGroup style={{width: fieldWidth}}>
        <h3>{t('Template Details')}</h3>
        <FormGroup key="name"
                   validationState={isTemplateFieldValid('name')}>
          <ControlLabel>{t('Name')}</ControlLabel>
          <FormControl type={'text'}
                       style={{width: '95%', fontSize: '13px'}}
                       value={template.name}
                       onChange={(v) => updateTemplateField('name', v)}/>
        </FormGroup>
        <FormGroup key="description"
                   validationState={isTemplateFieldValid('description')}>
          <ControlLabel>{t('Description')}</ControlLabel>
          <FormControl type={'text'}
                       style={{width: '95%', fontSize: '13px'}}
                       value={template.description}
                       onChange={(v) => updateTemplateField('description', v)}/>
        </FormGroup>
        <FormGroup key="report_type"
                   validationState={isTemplateFieldValid('report_type')}>
          <ControlLabel>{t('Report Type')}</ControlLabel>
          <div>
            <TemplateDropdown id='report_type' dataset={DROPDOWNS.report_type}/>
          </div>
        </FormGroup>
        <FormGroup key="type"
                   validationState={isTemplateFieldValid('type')}>
          <ControlLabel>{t('Template Type')}</ControlLabel>
          <div>
            <TemplateDropdown id='template_type'
                              dataset={DROPDOWNS.template_type}/>
          </div>
        </FormGroup>
        {
          !template.built_in &&
          <FormGroup key="prerequisites" validationState={'success'}>
            <ControlLabel>{t('Prerequisites')}</ControlLabel>
            <div className="ebase-4-col-grid">
              {
                ReportTaskTypes.map(tt => {
                  return (<Checkbox id="default_report"
                                    checkboxClass="icheckbox_minimal-grey"
                                    checked={isRequiredTask(tt)}
                                    label={'<span class="control-label">&nbsp;' +
                                    t(tt) + '</span>'}
                                    onChange={(ev, checked) => setRequiredTask(
                                      tt, checked)}>
                  </Checkbox>);
                })
              }
            </div>
          </FormGroup>
        }
        <FormGroup key="default" validationState={'success'}>
          <ControlLabel>{t('Options')}</ControlLabel>
          <div>
          <Checkbox id="default_report"
                    checkboxClass="icheckbox_minimal-grey"
                    checked={template?.default}
                    label={'<span class="control-label">&nbsp;'+t('Project Default')+'</span>'}
                    onChange={(ev, checked) => updateTemplateCheckField('default', checked)}>
          </Checkbox>
          </div>
        </FormGroup>
        <FormGroup key="file"
                   validationState={isTemplateFieldValid('templateFile')}>
          <ControlLabel>{t('Template File')}</ControlLabel>
          <div>
            <div>
            <FormControl type="text"
                         readOnly
                         value={templateFile ? templateFile.name : ''}
                         style={{width: '95%', fontSize: '13px', marginBottom:10}} >
            </FormControl>
            </div>
            <div>
            <EButton onClick={() => uploadFileRef?.current?.click()}
                     type="add">{t('Select')}</EButton>
            <input type="file" ref={uploadFileRef}
                   style={{display: 'none'}}
                   onChange={(e) => reportFileChanged(e)}/>
            </div>
          </div>
        </FormGroup>
      </FormGroup>

    );

    let buttons = (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        borderTop: '1px solid #eaeaea',
      }}>
        <EButton onClick={() => SessionManager.shared().goto('/templates')}
                 type="cancel">{t('Cancel')}</EButton>
        <div style={{marginLeft: 25}}>
          <EButton onClick={saveTemplate} type={isNew ? 'add' : 'done'}
                   disabled={!canUpdate}>{t(
            isNew ? 'Create Template' : 'Update Template')}</EButton>
        </div>
        <div style={{marginLeft: 25}}>
          <EButton onClick={()=>downloadTemplate()} disabled={isNew} type="download">
            {t('Download Template')}
          </EButton>
        </div>
      </div>
    );

    content = (
      <div>
        {clientInput}
        {buttons}
      </div>
    );

    // let dropDown = (
    //     <DropdownButton title={selected}>
    //         {options}
    //     </DropdownButton>
    // );

  }

  return (

    <div>
      <ContainerHeader title={t('Edit Template')}
                       crumbs={['/', '/templates', isNew ? t('New Template') : t('Edit Template')]}
                       dimensions={dimensions}
                       subtitle={t(
                         saving ? 'Saving' : (loading ? 'Loading' : ''))}/>
      <PageWrapper dimensions={dimensions}>
        <EbasePanel>
          {content}
        </EbasePanel>
      </PageWrapper>
    </div>

  );

};

export {TemplateEditView};
