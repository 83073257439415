import React from 'react';
import sitedata from '../utils/sitedata'
import 'icheck/skins/all.css'
import PropTypes from 'prop-types';

import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import {Table} from 'react-bootstrap'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import HubHeader from "../components/common/HubHeader"
import HubBox from "../components/common/HubBox"
import envid from "../utils/envid"
import ProjectSidebarWrapper from "../components/common/ProjectSidebarWrapper"

import {ManageReportsViewContainer, ManageReportsModalContainer} from './ManageReportsView'

import ComplianceStatusContainer from '../components/containers/ComplianceStatusContainer'
import ComplianceFilter from "../utils/ComplianceFilter";
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class ComplianceHomeView extends React.Component {

    constructor()
    {
        super();
        this.state = {showReportTemplates:false};
    }

    showComplianceManager()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let filter = new ComplianceFilter();

        SessionManager.shared().goto("/compliance/" + project_id + "/manage/"+filter.toString()+"/none/setup");
    }

    editProject()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        SessionManager.shared().goto("/projects/project/" + project_id+'/info');
    }

    render() {
        const {t} = this.props;

        let page_title = t("Compliance");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        //let report_rows = null;

        if (this.props.isLoading || this.props.report == null)
        {
            let p = null;

            return <div>
                        <ContainerHeader title={this.props.makeTitle(p, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', page_title]} dimensions={this.props.dimensions}/>
                        <PageWrapper dimensions={this.props.dimensions}>
                            <Spinner/>;
                        </PageWrapper>
                    </div>
        }

        let project = this.props.project;

        let titleStyle = {fontSize:"larger", fontWeight:"normal", borderBottom:"1px solid #DDD", marginTop:-10, paddingBottom:15}; // borderBottom:"2px solid black"


        let names = [t("Compliance Status"), t("Generate Reports")];
        let status = [];
        // Work out the status for each hub box

        status.push(t("Ready"));
        status.push(t("Ready"));
        // Reports
        status.push(t("Ready"));

        //<HubBox name={names[1]} colour={envid.getObjectColours("Control")} icon={envid.getObjectIcons("Control")} status={status[1]} hasPoint={true}    key={1}/>

        let box_header = (
            <HubHeader onSelect={(index) => SessionManager.shared().goto("/compliance/" + project_id + "/home/" + index)} selected={params.tabIndex}>
                <HubBox name={names[0]} colour={envid.getObjectColours("Control")} icon={envid.getObjectIcons("Control")} status={status[0]} hasPoint={true}    key={0}/>
                <HubBox name={names[1]} colour={envid.getObjectColours("Report")}   icon={envid.getObjectIcons("Report")} status={status[1]} hasPoint={false}   key={1}/>
            </HubHeader>
        );
        let selected_block = null;
        let tabIndex = parseInt(params.tabIndex);
        if (tabIndex === 0)
        {
            selected_block = (
                <div className="ibox float-e-margins">
                    <div className="ibox-content p-md">
                        {React.cloneElement(<ComplianceStatusContainer/>, this.props)}
                    </div>
                </div>);
        }
        else if (tabIndex === 2 )
        {

            let project_status_width = "15%";//"130px";
            let project_completion_width = "30%";//"250px";
            let project_action_width = "15%";//"100px";
            let label_text = t("Not Ready");
            let byline = "";
            let click = this.showComplianceManager.bind(this);
            let actions = <EButton type="edit" isTable={true} onClick={click}>{t("Edit")}</EButton>
            let completion = "";
            let label = "-warning";

            selected_block = (
                    <div className="ibox float-e-margins">
                        <div className="ibox-content p-md">

                            <div style={titleStyle}><span style={{paddingBottom:5, paddingTop:20}}>{t("Compliance Management")}</span></div>
                            <div className="project-list">
                                <Table className="table table-hover">
                                    <tbody>
                                    <tr onClick={click}>
                                        <td className="project-status" style={{width:project_status_width}}>
                                            <span className={"label label" + label}>{label_text}</span>
                                        </td>
                                        <td className="project-title">
                                            <EnvidObjectIcon type="Control"/>&nbsp;&nbsp;<strong>{t("Compliance Management")}</strong>
                                            <br/>
                                            <small>{byline}</small>
                                        </td>
                                        <td className="project-completion" style={{width:project_completion_width}}>
                                            {completion}
                                        </td>
                                        <td className="project-actions" style={{width:project_action_width}}>
                                            {actions}
                                        </td>
                                    </tr>                                    </tbody>
                                </Table>
                            </div>

                        </div>

                    </div>);
        }
        else if (tabIndex === 1)
        {
            selected_block = <ManageReportsViewContainer showReportTemplates={(b) => this.setState({showReportTemplates:b})}/>
        }
        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>

                    <ManageReportsModalContainer showReportTemplates={(b) => this.setState({showReportTemplates:b})}
                                        show={this.state.showReportTemplates}
                        />

                    <ProjectSidebarWrapper project={project} editProject={this.editProject.bind(this)}>
                        {box_header}
                        {selected_block}
                    </ProjectSidebarWrapper>

                </PageWrapper>
            </div>
        )
    }

}

ComplianceHomeView.propTypes = {
    report : PropTypes.object,
    project : PropTypes.object,
    isLoading : PropTypes.bool,
};


export default withTranslation()(ComplianceHomeView)
