import React, {useEffect, useState} from 'react';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import {Table, Modal} from 'react-bootstrap';
import Spinner from '../components/common/Spinner';
import EButton from '../components/common/EButton';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TemplateManager} from '../utils/TemplateManager';
import {useHistory} from 'react-router-dom';
import ScriptHelpContainer from "../components/containers/ScriptHelpContainer";
import envid from "../utils/envid";

const TemplatesAdminView = (props) => {

  const {t} = useTranslation();

  const history = useHistory();

  const templates = useSelector((state) => state.templates.templates);
  const isLoading = useSelector((state) => state.templates.isLoading);
  const dimensions = useSelector((state) => state.session.dimensions);

  const [showDelete, setShowDelete] = useState(false);
  const [templateId, setTemplateId] = useState();

    const [showScriptHelp, setShowScriptHelp] = useState(false);

  const DeleteTemplateModal = (props) => {
    return (
        <Modal show={showDelete} onHide={()=>setShowDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Delete Template')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('Are you sure that you want to delete this template')}</Modal.Body>
          <Modal.Footer>
            <EButton type="close" onClick={()=>setShowDelete(false)}>
              {t('Close')}
            </EButton>
              <EButton type="delete" onClick={()=>{deleteTemplate(templateId)}}>
                  {t('Delete')}
              </EButton>
          </Modal.Footer>
        </Modal>
    );
  }

    const ScriptHelpModal = (props) => {
        return (
            <Modal show={showScriptHelp} onHide={()=>setShowScriptHelp(false)} bsSize={"large"}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Script Help')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScriptHelpContainer />
                </Modal.Body>
                <Modal.Footer>
                    <EButton type="close" onClick={()=>setShowScriptHelp(false)}>
                        {t('Close')}
                    </EButton>
                </Modal.Footer>
            </Modal>
        );
    }

  const checkDeleteTemplate = (template_id) => {
    setTemplateId(template_id);
    setShowDelete(true);
  }

  const deleteTemplate = (template_id) => {
    setShowDelete(false);
    setTemplateId(undefined);
    TemplateManager.deleteTemplate(template_id);
  }

  const editTemplate = (template_id) => {
    history.push('/templates/manage/'+template_id);
  }

  const showScriptHelpClick = () => {
      setShowScriptHelp(true);
  }

  useEffect(() => {
    if (!templates && !isLoading) {
      TemplateManager.loadReportTemplates();
    }
  }, [templates, isLoading]);

  let body;

  // console.log("USersView;:");
  // console.log(this.props);

  if (isLoading) {
    body = <Spinner/>;
  } else {

    if (!templates || !templates.length) {
      body = <tbody>
      <tr>
        <td>{t('No Data')}</td>
      </tr>
      </tbody>;
    } else {

      templates.sort((a, b) => {

        let ret = a.report_type.localeCompare(b.report_type);
        if (ret === 0) {
          ret = a.name.localeCompare(b.name);
        }
        if (ret === 0) {
          ret = a.description.localeCompare(b.description);
        }
        if (ret === 0) {
          ret = a.type.localeCompare(b.type);
        }
        if (ret === 0) {
          ret = a.template_id.localeCompare(b.template_id);
        }
        return ret;

      });

      const rows = templates.map((template) =>

        <tr key={template.template_id}>
          <td>{t(template.report_type)}</td>
          <td>{template.name}</td>
          <td>{t(template.type)}</td>
          <td style={{textAlign:'center'}}>{template.default && (<i className="fa fa-check"/>)}</td>
          <td style={{textAlign:'center'}}>{template.built_in && (<i className="fa fa-check"/>)}</td>
          <td>
            <EButton type="delete"
                     isTable={true}
                     disabled={template?.built_in}
                     onClick={()=>{checkDeleteTemplate(template.template_id)}}>
              {t('Delete')}
            </EButton>
            &nbsp;
            <EButton type="edit"
                     isTable={true}
                     style={{marginLeft:10}}
                     onClick={()=>{editTemplate(template.template_id)}}>
              {t('Edit')}
            </EButton>
          </td>
        </tr>,
      );
      body = <tbody>{rows}</tbody>;
    }

    body = (
      <Table className="table table-hover">
        <thead>
        <tr>
          <th>{t('Report')}</th>
          <th>{t('Name')}</th>
          <th>{t('Type')}</th>
          <th style={{textAlign:'center'}}>{t('Project Default')}</th>
          <th style={{textAlign:'center'}}>{t('Built In')}</th>
        </tr>
        </thead>
        {body}
      </Table>
    );
  }

  let addButton = (
    <div style={{marginTop: '30px'}}>
      <EButton type="add" onClick={()=>{editTemplate('new')}}>
        {t('New Template')}
      </EButton>
    </div>);

  let rightComponent = (
      <div style={{display:'flex', justifyContent:'space-between',marginTop: '30px', marginLeft:'-100px'}}>
          <EButton type="add" onClick={()=>{editTemplate('new')}}>
              {t('New Template')}
          </EButton>
          <EButton style={{backgroundColor:envid.allColours['communication']}} onClick={()=>{showScriptHelpClick()}}>
              {t('Script Help {?}')}
          </EButton>
      </div>
  )

  return (
    <div>
      <ContainerHeader title={t('Manage Templates')} crumbs={['/', '/templates']}
                       dimensions={dimensions}
                       rightComponent={addButton}/>
      <PageWrapper dimensions={dimensions}>
        <EbasePanel>
            <div className={'pull-right'} style={{marginRight: '42px'}}>
            <EButton style={{backgroundColor:envid.allColours['communication']}} onClick={()=>{showScriptHelpClick()}}>
                {t('Script Help')}
            </EButton>
            </div>
          {/*<FormGroup style={{width: '25%'}}>*/}
          {/*  <ControlLabel>{t('Username Filter')}</ControlLabel>*/}
          {/*  <FormControl type="text" value={this.state.filterString}*/}
          {/*               onChange={this.onFilterChange.bind(this)}/>*/}
          {/*</FormGroup>*/}
          {body}
        </EbasePanel>
      </PageWrapper>
      <DeleteTemplateModal/>
        <ScriptHelpModal/>
    </div>
  );

};

export {TemplatesAdminView};
