import React from 'react';
import {Grid, Row, Col, Panel, Button} from 'react-bootstrap'
import {Redirect} from "react-router-dom";
import { withTranslation} from "react-i18next";

class Login extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {pending:false, username:"", password:"",showAccountHelp:false};

        this.userChanged = this.userChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.try_login = this.try_login.bind(this);
        this.userField = null;
    }

    userChanged(event) {
        this.setState({username: event.target.value});
    }

    passwordChanged(event) {
        this.setState({password: event.target.value});
    }

    try_login()
    {
        this.setState({pending : true});
        this.props.onLogin(this.state.username, this.state.password);
    }

    componentDidMount() {
        if (this.userField != null) {
            this.userField.focus();
        }
    }

    render() {

        const {t} = this.props;
        // console.log("Login:: ",this.props);

        let isLoading = this.state.pending && this.props.error==null;

        let error = null;
        if (this.props.error != null)
        {
            error = (
                <div><span className="label label-warning">{this.props.error}</span><br/><br/></div>
            )


        }

        if (this.props.isAuthenticated){
            if (this.props.siteData != null && this.props.siteData.dataset == null) {
                return <Redirect to="/libraries"/>;
            }
            if (this.props.siteData != null && this.props.siteData.dataset != null) {
                return <Redirect to="/select"/>;
            }
        }

        var forgotPassword = "";
        var createAccount = "";
        var createAccountButton = "";

        if(this.state.showAccountHelp){
            forgotPassword = (<a href="#">
                <small>{t("Forgot password?")}</small>
            </a>);
            createAccount = (<p className="text-muted text-center">
                <small>{t("Do not have an account?")}</small>
            </p>);
            createAccountButton = <Button className="white block" href="register.html"> {t('Create an account')} </Button>;

        }
            return (
                <div className="ebase-login-blur">
                <Grid className="gray-bg loginColumns animated fadeInDown">

                    <Row>
                        <Col md={6}>
                            <h2 className="font-bold">{t('Welcome to eBase')}</h2>
                            <p>{t("login-description-line-1")}</p>
                            <p>{t("login-description-line-2")} <a href="mailto:ebase@xodusgroup.com">{t('here')}.</a></p>
                            {/*<Trans i18nKey="login-description-html-1">*/}
                            {/*    <p>eBase is cloud based EIA. Encouraging collaboration, simplifying the process, and bringing you the answers sooner.</p>*/}
                            {/*    If you would like to sign up or you have any problems signing in, email us <a href="mailto:ebase@xodusgroup.com">here.</a>*/}
                            {/*</Trans>*/}
                        </Col>
                        <Col md={6}>
                            <Panel className="m-t">

                                {error}
                                <form>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder={t("Username")} required=""
                                               onChange={this.userChanged} ref={r => this.userField = r}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" value={this.state.password}
                                               placeholder={t('Password')} onChange={this.passwordChanged} />
                                    </div>
                                    <Button type="submit" bsStyle="primary" className="full-width m-b"
                                            onClick={this.try_login} disabled={isLoading} onSubmit={this.try_login}>
                                        {isLoading ? t("Logging In") : t("Login")}
                                    </Button>
                                </form>
                                {forgotPassword}
                                {createAccount}
                                {createAccountButton}
                            </Panel>
                        </Col>
                    </Row>

                    <hr/>
                    <div className="row">
                        <div className="col-md-6">
                            {t("Copyright Xodus Green Light Patented AU2017100353")}
                        </div>
                        <div className="col-md-6 text-right">
                            <small>{t('© 2020')}</small>
                        </div>
                    </div>

                </Grid>
            </div>
            )

    }

}

export default withTranslation()(Login)
