import React from 'react';
import {
    FormControl,
} from 'react-bootstrap';
import "../ladda.min.css";
import '../../node_modules/ladda/js/spin.js';
import '../../node_modules/ladda/js/ladda.js';
import {withTranslation} from "react-i18next";
import envid from "../utils/envid";


class DataPublishing extends React.Component {

    constructor(props)
    {
        super(props);
        this.onPublishClick = this.onPublishClick.bind(this);
    }


    onPublishClick(e){
        //console.log("  >>>> publish Click");
        this.props.doChangePublished(e);

    }

    render() {
        const {t} = this.props;

        //console.log("------- publish view -------");
        // console.log("props: "+this.props.currentObject);

        let publishState = this.props.currentObject.object.publishingInfo.state;

        let pubString = t("Object") + " ";

        pubString = t('object-publish-state', {state: t(publishState)});

        let date = new Date(this.props.currentObject.object.documentInfo.last_update_time);
        const lastUpdate = date.toLocaleString();
        date = new Date(this.props.currentObject.object.documentInfo.creation_time);
        let created = date.toLocaleString();
        let authorDetails = this.props.currentObject.author;
        let author = t("Unknown");
        if (authorDetails !== null && authorDetails !== undefined) {
            author = authorDetails.firstname + " " + authorDetails.lastname;//+ " ("+authorDetails.username+")";
        }

        let lastUserDetails = t("Unknown");
        if(this.props.currentObject.last_update_user !== null && this.props.currentObject.last_update_user !== undefined){
            lastUserDetails = this.props.currentObject.last_update_user.firstname +" " +this.props.currentObject.last_update_user.lastname;
        }

        return (
            <div className="ibox-content" style={this.props.style}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="panel panel-default">
                            <div className="panel-heading"><h3>{t('Document Info')}</h3></div>
                        <div className="panel-body p-lg text-left">
                            <div className="row">
                                <div className="col-lg-3">
                                    <h4>{t("Created")}:</h4>
                                </div>
                                <div className="col-lg-6">
                                    <p>{created}<br/>{t("by")} {author}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <h4>{t("Last Updated")}:</h4>
                                </div>
                                <div className="col-lg-6">
                                    <p>{lastUpdate}<br/>{t("by")} {lastUserDetails}</p>
                                </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                    <div className="panel" style={{backgroundColor: envid.getPublishStateColour(publishState)}}>
                        <div className="panel-heading" style={envid.getPublishTextStyle(publishState)}><h3>{pubString}</h3></div>
                        <div className="panel-body p-lg text-left">
                            <div className="row">
                                <div className="col-lg-2">
                                    <h4>{t('Publish State')}</h4>
                                </div>
                                <div className="col-lg-3">
                                    <FormControl componentClass="select" placeholder="select"
                                                 value={publishState}
                                                 onChange={this.onPublishClick}>
                                        {
                                            envid.getPublishStates().map((s) => {
                                                return (<option key={s} value={s}>{t(s)}</option>);
                                            })
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default withTranslation()(DataPublishing)
