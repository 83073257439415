import React from 'react';
import Measure from 'react-measure';

class HubHeader extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {width:0};
    }

    setWidth(width)
    {
        this.setState({width:width ?? 0});
    }

    onSelect(index)
    {
        this.props.onSelect(index);
    }

    render() {

        let selected_index = this.props.selected;
        let box_height = 85;
        let selector_height = 35;
        let selector_pos = 0;
        let selector_width = 50;

        let theWidth = this.state.width - 4;
        let props = {hubstyle:{marginRight:"2%", marginLeft:"2%", width:"16%", height:box_height}};
        let fullWidth = theWidth;

        if (fullWidth == null) fullWidth = 0;
        if (theWidth > 0)
        {
            let nBoxes = 5;
            // round it down
            let w = Math.round(-0.5 + theWidth/nBoxes);
            let maxWidth = 200;

            selector_pos = selected_index*w + w/2;

            if (w > maxWidth)
            {
                w = maxWidth;
            } else {
                w -= 3;
            }


            let gap = (theWidth - (5*w))/10;
            props = {hubstyle:{marginRight:gap, marginLeft:gap, width:w, height:box_height}};

        }

        let children = [];
        for (let i=0; i<this.props.children.length; ++i)
        {
            props.selected = (i===selected_index);
            props.key = i;
            props.onSelect = this.onSelect.bind(this, i);
            let child = this.props.children[i];
            children.push(React.cloneElement(child, props));
        }

        // draw the gutter thingo
        let gutter = (
            <svg width={this.state.width} height={selector_height}>
                <polyline points={[0, selector_height,
                                   selector_pos-(selector_width/2), selector_height,
                                    selector_pos, 0,
                                    selector_pos+(selector_width/2), selector_height,
                                   fullWidth, selector_height]} style={{stokeWidth:2, stroke:"lightgray", fill:"white"}}/>
                <clipPath>
                    <polyline points={[0, selector_height,
                        selector_pos-(selector_width/2), selector_height,
                        selector_pos, 0,
                        selector_pos+(selector_width/2), selector_height,
                        fullWidth, selector_height]}/>

                </clipPath>
            </svg>
        );

        return (
            <Measure bounds onResize={(dimensions) => this.setWidth(dimensions.bounds.width)}
                     whitelist={["height", "width"]}>
                { ({measureRef}) => (
                    <div ref={measureRef}>
                        <div style={{
                            marginLeft: "0%",
                            width: this.state.width,
                            height: box_height,
                            marginBottom: 5
                        }}>
                            {children}
                        </div>
                        <div style={{marginBottom: -5, paddingBottom: 0}}>
                            {gutter}
                        </div>
                    </div>
                )}
            </Measure>
        )
    }
}

export default HubHeader
