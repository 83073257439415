import React from 'react';
import taskUtil from '../utils/taskUtil'
import sitedata from "../utils/sitedata"
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import ReportTaskQuestion from './ReportTaskQuestion'
import ReportInputSummary from './ReportInputSummary'
import TaskProgress from '../components/common/TaskProgress';
import AnswerLabel from '../components/common/AnswerLabel'
import SessionManager from "../utils/SessionManager";
import {Trans, withTranslation} from "react-i18next";

class ReportTaskView extends React.Component {

    constructor()
    {
        super();

        this.state = {answer:""};
        this.renderObject = this.renderObject.bind(this);
        this.getParentTabIndex = this.getParentTabIndex.bind(this);
    }

    gotoAnswer(position)
    {
        this.props.gotoAnswer(position);
    }

    renderObject(object)
    {
        let style_small_space = {marginRight:"5px"};
        let ret = [];

        switch (object.type)
        {
            case "AspectActivityLink":
                let aspect = taskUtil.getObjectFromLink(this.props.task, object, "Aspect");
                let activity = taskUtil.getObjectFromLink(this.props.task, object, "Activity");


                ret.push(<EnvidObjectIcon type="Aspect" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                if (aspect == null) {
                    ret.push("Unknown");
                } else {
                    ret.push(aspect.details.name);
                }

                ret.push(<i style={{fontWeight:"normal"}}> caused by </i>);

                ret.push(<EnvidObjectIcon type="Activity" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                if (activity == null) {
                    ret.push("Unknown");
                } else {
                    ret.push(activity.details.name);
                }

                break;
            case "Impact":
                let impact = taskUtil.getObject(this.props.task, "ImpactType", object.details.impact_type_id);

                ret.push(<EnvidObjectIcon type="Impact" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                if (impact == null) {
                    ret.push(object.details.impact_type_id);
                } else {
                    ret.push(impact.details.name);
                }
                break;

            case "ImpactReceptorLink": {
                let receptor = taskUtil.getObjectFromLink(this.props.task, object, "Receptor");
                let impact = taskUtil.getObjectFromLink(this.props.task, object, "Impact");
                let impactType = taskUtil.getImpactType(this.props.task, impact);
                ret.push(<EnvidObjectIcon type="Impact" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                ret.push(<span>{impactType} on {receptor.details.name}</span>);

                break;
            }
            case "ControlReceptorLink": {
                let control = taskUtil.getObjectFromLink(this.props.task, object, "Control");
                let receptor = taskUtil.getObjectFromLink(this.props.task, object, "Receptor");

                ret.push(<span>on </span>);
                ret.push(<EnvidObjectIcon type="Control" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                if (receptor == null) {
                    ret.push(object.details.name);
                } else {
                    let control_name = taskUtil.getObjectFromLink(this.props.task, control.details.control_name_id, "ControlName");
                    if (control_name == null) {
                        ret.push("No Control Name");
                    } else {
                        ret.push(<i>{control_name.details.name}</i>);
                    }
                }
                break;
            }
            case "Control": {
                let control = object;

                ret.push(<EnvidObjectIcon type="Control" showName={false}/>);
                ret.push(<span style={style_small_space}/>);
                let control_name = taskUtil.getObject(this.props.task, "ControlName", control.details.control_name_id);
                if (control_name == null) {
                    ret.push("No Control Name");
                } else {
                    ret.push(control_name.details.name);
                }
                break;
            }
            case "Activity":
            case "Aspect":
            default:
                ret.push(<EnvidObjectIcon type={object.type} showName={false}/>);
                ret.push(<span>&nbsp;&nbsp;</span>);
                ret.push(object.details.name);
                break;
        }

        return ret;

    }

    renderList(type, list, position, lastArray)
    {
        if (list == null || list.length === 0) return null;
        if (position == null) position = [];
        if (lastArray == null) lastArray = [];

        let index = 0;

        list.sort((a,b) => {

            let objA = null;
            let objB = null;

            if(a.link != null){
                objA = taskUtil.getLinkObject(this.props.task, a)
            }else{
                objA = taskUtil.getObject(this.props.task, type, a.object_id);
            }

            if(b.link != null){
                objB = taskUtil.getLinkObject(this.props.task, b)
            }else{
                objB = taskUtil.getObject(this.props.task, type, b.object_id);
            }

            return objA.displayName.localeCompare(objB.displayName);

        });


        let ret = [];
        for (let i=0; i<list.length; ++i)
        {
            let isLast = i >= list.length-1;

            let item = list[i];

            let object_id = item.object_id;
            let myPosition = position.slice();
            myPosition.push(index++);

            let myLastArray = lastArray.slice();
            myLastArray.push(isLast);

            var object = null;

            if (item.link != null) {
                object = taskUtil.getLinkObject(this.props.task, item)
            } else {
                object = taskUtil.getObject(this.props.task, type, object_id);
            }

            if (object == null)
            {
                object = {type:type, details:{name:"Empty Object"}};
            }

            let answer = taskUtil.getAnswer(this.props.task, object_id);
            let callback = null;
            if (answer == null)
            {
                answer = {answer:""};
            } else {
                callback = this.gotoAnswer.bind(this, myPosition);
            }

            let height = 30;
            let border = "1px solid #DDD";
            let borderHeight = height;

            let isChildren = answer == null || answer.answer === "Yes" || answer.answer === "";

            if (isLast){//} && !isChildren) {
                borderHeight = height/3;
            }

            let borders = [];
            for (let k=0; k<lastArray.length; ++k)
            {
                if (!lastArray[k])
                {
                    borders.push(<div style={{position:"absolute", left:k*20, top:0, width:1, height:height, borderLeft:border}}></div>);
                }
            }
            borders.push(<div style={{position:"absolute", left:position.length*20, top:0, width:1, height:borderHeight, borderLeft:border}}></div>);
            if (i===0) {
                borders.push(<div style={{position:"absolute", left:position.length*20, top:-10, width:12, height:10+height/3, borderBottom:border}}></div>);
            } else {
                borders.push(<div style={{position:"absolute", left:position.length*20, top:0, width:12, height:height/3, borderBottom:border}}></div>);
            }

            let colour = "";
            if (answer.answer === "No") {//} || answer.answer == "DontKnow") {
                colour = "lightgray";
            }
            ret.push(
                <div>
                    <div onClick={callback} style={{position:"relative", textAlign:"left", height:height, paddingLeft:15+position.length*20, color:colour}}>
                            {borders}
                            <span className={(callback==null)?"":"tree-item"}>
                                &nbsp;
                                {this.renderObject(object)}
                                &nbsp;&nbsp;<AnswerLabel risk={answer.risk} riskName={taskUtil.riskName(this.props.task.risk_matrix, answer.risk)} answer={answer.answer}/>
                            </span>
                    </div>
                </div>);

            if (isChildren)
            {
                ret = ret.concat(this.renderList(item.listType, item.list, myPosition, myLastArray));
            }
        }

        return ret;
    }

    getParentTabIndex()
    {
        let params = sitedata.getParams(this.props);
        switch (params.task)
        {
            case "AspectActivity" : return 0;
            case "Receptor" : return 0;
            case "TechnicalInputs" : return 0;
            case "Impact" : return 1;
            case "EnvironmentalInputs" : return 0;
            case "Control" : return 2;
            case "Risk" : return 3;
        }
    }

    render() {

        console.log("ReportTaskView::", this.props);

        const {t} = this.props;

        let crumbs = [];
        let body;
        let progress = null;
        let title = "";
        let params = sitedata.getParams(this.props);

        if (this.props.isLoading || this.props.task == null || this.props.task.object.type==="Activity") {
            body = <Spinner/>;
            crumbs = ['/', '/projects', '/report-defn/Summary'];
        }
        else
        {
            let taskPosition = this.props.taskPosition;

            let project = this.props.project;
            let structure = this.props.task.object.structure;

            let task_item = taskUtil.findTaskItem(this.props.task.object.type);

            let bRisk = this.props.task.object.type === "Risk";

            if (task_item == null)
            {
                console.log("Task Item is null");
                console.log(this.props.task);
            }
            title = t(task_item.name) + " " + t("Summary");

            crumbs = ['/', '/projects', '/report-defn/' + project.object_id + "/summary/" + this.getParentTabIndex()];

            let task_type = params.task;
            let item_name = this.props.task.object.type;
            if (item_name === "AspectActivity") item_name = t("Activity or Aspect");

            // inputs summary page
            let bIncludeWrapper = true;
            if (task_type === "EnvironmentalInputs" || task_type === "TechnicalInputs") {
                let summary = <ReportInputSummary/>
                body = <div>
                    {React.cloneElement(summary, this.props)}

                    <div className="pull-right"
                         onClick={() => SessionManager.shared().goto('/report-defn/' + project.object_id + "/summary/" + this.getParentTabIndex())}>
                        <EButton>{t("Done")}</EButton></div>
                    <br/>

                </div>

                crumbs.push(t("Summary"));

            }
            else if (taskPosition == null) {

                // show the summary Page

                let message = null;
                if (bRisk) {
                    let options = [];
                    console.log()
                    for (let i = 0; i < this.props.task.risk_matrix.residual_risk.length; ++i) {
                        let severity = this.props.task.risk_matrix.residual_risk[i];
                        if (options !== "") options.push(<span>, </span>);
                        options.push(<span>{severity.name} <AnswerLabel risk={i} riskName={severity.name}/></span>);
                    }

                    message = <div>
                        <Trans i18nKey="question-workflow-desc-risk">
                        <h3>Summary</h3>
                        <p>
                            This summary details the assessment workflow for your project.
                            Questions you have not answered are marked with a <AnswerLabel answer="New"/>.
                        </p>
                        <p>Click the {{item_name}} to change your response.</p>
                        </Trans>
                    </div>
                } else {
                    message = <div>
                        <Trans i18nKey="question-workflow-desc-norisk">
                        <h3>Summary</h3>
                        <p>
                            This summary details the assessment workflow for your project.
                            This summary includes <strong>Yes</strong> <AnswerLabel answer="Yes"/> and
                            <strong> No</strong> <span style={{color: "lightgray"}}><AnswerLabel answer="No"/></span>.
                            Questions you
                            have not answered are marked with a <AnswerLabel answer="New"/>.
                        </p>
                        <p>Click the Receptor to change your response.</p>
                        </Trans>
                    </div>

                }
                // console.log("structure.list");
                // console.log(structure.list);
                body = <div>
                    {message}
                    <br/>
                    <div>

                        {this.renderList(structure.listType, structure.list, null, null)}

                    </div>
                    <div className="pull-right"
                         onClick={() => SessionManager.shared().goto('/report-defn/' + project.object_id + "/summary/" + this.getParentTabIndex())}>
                        <EButton>{t("Done")}</EButton></div>
                    <br/>

                </div>
                crumbs.push(t("Summary"));

            }
            else {
                // ask a question
                let question = <ReportTaskQuestion/>;
                body = React.cloneElement(question, this.props);
                title = t(task_item.name) + " " + t("Questions");

                crumbs.push(<a onClick={this.props.gotoAnswer.bind(this, "HOME")}>{t("Summary")}</a>);
                crumbs.push(t("Task Questions"));
                bIncludeWrapper = false;

            }

            if (bIncludeWrapper) {
                body = (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content p-md">
                                    {body}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            progress = <div style={{marginTop: 10, paddingBottom: 0, marginBottom: -10}}><TaskProgress strokeWidth={10}
                                                                                                           radius={35}
                                                                                                           percentage={this.props.task.object.percentage}/>
            </div>
        }

        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, title)} crumbs={crumbs}
                                 rightComponent={progress}
                                 dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    {body}
                </PageWrapper>
            </div>
        )

    }

}


export default withTranslation()(ReportTaskView)
