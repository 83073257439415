import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ebase_logo from "../../img/ebase-logo.png";
import envid from "../../utils/envid";
import {useSelector} from "react-redux";

const Footer = React.forwardRef((props, ref) => {

    const {t} = useTranslation();
    const [showVersion, setShowVersion] = useState(false);
    const serverVersion = useSelector((state) => state.session.siteData?.serverVersion);

    let rightText = t("Xodus Green Light © 2020 Patented AU2017100353");

    if (props.copyRight != null){
        rightText = props.copyRight;
    }
    return (
        <div ref={ref} className="footer fixed">
            <div className="pull-right">
                <strong>{t('Copyright')}</strong> {rightText}
            </div>
            <div>
                <img alt="Ebase Logo" src={ebase_logo} style={{width:"91px", height:"33px", marginTop:"-10px", marginBottom:"-10px"}}/>
                <span onMouseEnter={() => setShowVersion(true)} onMouseLeave={() => setShowVersion(false)} className={showVersion===true ? "text-muted" : "text-white"} style={{fontSize:8, marginLeft:20}}>v{serverVersion} | v{envid.getAppVersion()}</span>
            </div>
        </div>
    )

});

export default Footer;
