/**
 * Created by lewellyn on 18/9/18.
 * Updated: Andy on 17/12/21
 */
import React from 'react';
import sitedata from '../utils/sitedata'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import DatasetSelectView from '../views/DatasetSelectView'
import EButton from '../components/common/EButton'
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";
import {FormControl, FormGroup, ControlLabel} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"

class ClientLibrarySelectView extends React.Component {

    constructor(props){

        super(props);

        this.onFilterChange = this.onFilterChange.bind(this);
        this.setFilterToEmptyString = this.setFilterToEmptyString.bind(this);

        this.state = {inputString:"", filter:""};
        this.filterRef = React.createRef();

    }

    onFilterChange(event){

        let value = event.target.value;

        this.setState({inputString:value});
    }

    setFilterToEmptyString(){

        // reset input string after pressing 'clear' button and put focus back on input text field
        this.setState({inputString:""},
            () => {this.filterRef.current.focus()}
            );

    }

    render() {

        const {t} = this.props;

        let body = <tbody></tbody>;

        let canEdit = sitedata.checkWriteAccess(this.props, "system");

        let title = t("Libraries");

        let addButton = null;

        if (canEdit) {
            addButton =(
                <div style={{marginTop: "30px"}}>
                    <EButton type="add" onClick={() => this.props.manageClient("new")}>{t("New Company")}</EButton>
                </div>);
            title = t("Clients & Libraries");
        }

        if (this.props.isLoading || this.props.doLoad)
        {
            body = <Spinner/>
        }
        else {

            // set libraries to be a filtered by company and library then sort
            let libraries = sitedata.getClientLibraries(this.props)

            if (libraries === undefined) {
                body = <tbody><tr><td>{t("No Data")}</td></tr></tbody>
            } else if (libraries.length === 0) {
                body = <tbody><tr><td>{t("No Data")}</td></tr></tbody>
            } else {

                this.state.filter = this.state.inputString.trim();

                // select companies or libraries containing filter string
                if(this.state.filter.trim().length > 0){
                    libraries = libraries.filter( lib => lib.client.name.toLowerCase().includes(this.state.filter.toLowerCase())
                        || lib.libraries.some(l => l.name.toLowerCase().includes(this.state.filter.toLowerCase())))
                }

                libraries.sort(l => l.libraries.name);

            }

            body = (
                <div className="row">
                    <DatasetSelectView clientLibraries={libraries}
                                    selectDataset={SessionManager.shared().selectDatasetAccess}
                                    canEdit={canEdit}
                                    editClick={this.props.manageClient}
                    />
                </div>
            );
        }

        return (
            <div>
                <ContainerHeader crumbs={['/','/libraries']} title={title}  dimensions={this.props.dimensions} rightComponent={addButton}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <div className="row">
                        <div className="col-lg-12">
                        <EbasePanel>
                            <span>
                                <FormGroup style={{width:"25%"}}>
                                    <ControlLabel>{t("Library Filter")}</ControlLabel>
                                    <FormControl inputRef={this.filterRef} autoFocus type="text"  value={this.state.inputString} onChange={this.onFilterChange.bind(this)}/>
                                </FormGroup>
                                <FormGroup>
                                    <EButton type="standard" onClick={this.setFilterToEmptyString}>Clear</EButton>
                                </FormGroup>
                            </span>
                            {body}
                        </EbasePanel>
                                </div>
                            </div>
                </PageWrapper>
            </div>
        );
    }
}

export default withTranslation()(ClientLibrarySelectView)
