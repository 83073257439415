import React from 'react';
import {
    FormControl,
    ControlLabel,
    FormGroup,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalBody, ModalFooter
} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import envid from '../utils/envid';
import EButton from '../components/common/EButton';
import EnvidObjectIcon from '../components/common/EnvidObjectIcon';

import moment from 'moment';

import {ObjectionStatusLabel} from '../components/common/StakeholderUtils';
import {withTranslation} from 'react-i18next';
import Editor from "../components/common/Editor";

class StakeholderObjectionsListView extends React.Component {

    constructor() {
        super();

        this.state = {title: "", chapter: "", isEditObjection: false, editObjection: null, desc: null};

        this.editObjectionClick = this.editObjectionClick.bind(this);
    }

    rowClick(objection_id) {
        this.props.onClick(objection_id);
    }

    editObjectionClick(objection, e) {
        e.stopPropagation();
        const details = objection.details;
        this.setState({isEditObjection: true, title:details.name,status: details.status, chapter: details.chapter, desc: details.desc?.text,editObjection: objection})
    }

    clearState() {
        this.setState({
            isEditObjection: false,
            title: "",
            desc: "",
            editObjection: null
        });
    }

    saveObjection() {
        let title = this.state.title;
        let desc = this.state.desc;
        let chapter = this.state.chapter;
        let objection = this.state.editObjection;
        this.props.updateObjection(objection?.object_id, {name: title, chapter: chapter, desc: desc});

        this.clearState();
    }

    render() {

        const {t} = this.props;

        // console.log("StakeholderObjectionsListView RENDER");
        // console.log(this.props);
        // console.log("---------");

        let objections = this.props.objections;
        if (objections == null) objections = [];

        let body = <span>Hello</span>;

        let objectionModal = (
            <Modal show={this.state.isEditObjection} dialogClassName="objection-modal"
                   onHide={this.clearState.bind(this)}>
                <ModalHeader closeButton>
                    <ModalTitle>
                        <EnvidObjectIcon type="Objection"/>&nbsp;&nbsp;{t("Edit Objection")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <FormGroup key="status">
                        <ControlLabel>{t("Status")}</ControlLabel>
                        <FormControl componentClass="select"
                                     placeholder="select"
                                     value={this.state.status}
                                     disabled={this.state.isEditObjection}
                                     onChange={(event) => this.setState({status: event.target.value})}>
                            <option value="ASSESSING">{t("Assessing")}</option>
                            <option value="MERIT">{t("Has Merit")}</option>
                            <option value="NO_MERIT">{t("No Merit")}</option>
                        </FormControl>
                    </FormGroup>

                    <FormGroup key="name">
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <div>
                            <FormControl type='text' field="name"
                                         onChange={(event) => this.setState({title: event.target.value})}
                                         style={{width: "100%", fontSize: "13px"}}
                                         value={this.state.title}/>
                        </div>
                    </FormGroup>

                    <FormGroup key="chapter">
                        <ControlLabel>{t("Chapter")}</ControlLabel>
                        <div>
                            <FormControl type='text' field="name"
                                         onChange={(event) => this.setState({chapter: event.target.value})}
                                         style={{width: "100%", fontSize: "13px"}}
                                         value={this.state.chapter}/>
                        </div>
                    </FormGroup>

                    <FormGroup key="desc">
                        <ControlLabel>{t("Description")}<br/></ControlLabel>
                        <div>
                            <Editor model={this.state.desc}
                                    handleModelChange={(model) => {
                                        this.setState({desc: model})
                                    }}
                                    off={true}
                            />
                        </div>
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.clearState.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="done" onClick={this.saveObjection.bind(this)}>{t("Save")}</EButton>
                </ModalFooter>

            </Modal>
        );

        if (objections.length === 0) {
            body = <span>{t('No Objections')}</span>;
        } else {
            let rows = [];

            rows = objections.map((objection) => {
                let name = envid.getObjectField(objection, 'name');
                let status = envid.getObjectField(objection, 'status');
                let created_date = envid.getObjectField(objection, 'creation_time');

                if (created_date != null) {
                    created_date = moment(created_date).calendar(null, {sameElse: 'Do MMMM YYYY'});
                }

                // let updated_date = envid.getObjectField(objection, 'updated');
                // if (updated_date != null)
                // {
                //     updated_date = moment(updated_date).format("D MMM YYYY @ hh:mm a");
                // }

                let editButton = (
                    <EButton type="edit" isTable={true}
                             onClick={(e) => this.editObjectionClick(objection, e)}>{t('Edit')}</EButton>
                );
                if (this.props.readOnly){
                    editButton = null;
                }

                return (
                    <tr key={objection.object_id} style={{cursor: 'pointer'}}
                        onClick={this.rowClick.bind(this, objection.object_id)}>
                        <td><ObjectionStatusLabel status={status}/></td>
                        <td>{name}</td>
                        <td>{created_date}</td>
                        <td>{editButton}</td>
                        <td><i className="fa fa-angle-right"
                               style={{fontSize: '2em', color: '#636363'}}></i></td>
                    </tr>);
            });

            body = (
                <Table className="table table-hover">
                    <thead>
                    <tr>
                        <th>{t('Status')}</th>
                        <th>{t('Title')}</th>
                        <th>{t('Created')}</th>
                        <th style={{width: 1}}></th>
                        <th style={{width: 1}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            );
        }

        return (
            <div>
                {objectionModal}
                <div className="mail-box-header">
                    <h2>
                        <EnvidObjectIcon type="Objection"/>&nbsp;&nbsp;&nbsp;&nbsp;{t(
                        'Objections')} <span className="label label-success"
                                             style={{fontSize: 16}}>{/*this.props.objections.length*/}</span>
                    </h2>

                    <div className="mail-body">
                        {body}
                    </div>
                </div>

            </div>
        );

    }

}

export default withTranslation()(StakeholderObjectionsListView);
