import React from 'react';
import EnvidObjectIcon from '../components/common/EnvidObjectIcon'
import {Row, Col} from 'react-bootstrap'
import {Table} from 'react-bootstrap'
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import {Modal,ModalHeader, ModalBody, ModalFooter,ModalTitle} from 'react-bootstrap'
import Editor from '../components/common/Editor'
import envid from '../utils/envid'
import moment from 'moment'
import {EventSectionTitle} from '../components/common/StakeholderUtils'

import EButton from "../components/common/EButton"
import {ObjectionStatusLabel} from "../components/common/StakeholderUtils"
import {withTranslation} from "react-i18next";


class StakeholderEventObjectionsView extends React.Component {

    constructor() {
        super();

        this.state ={title:"", chapter:"", isNewObjection:false, status:"ASSESSING", isEditObjection:false, editObjection: null};
        this.onSelected = this.onSelected.bind(this);
        this.getObjectionStatus = this.getObjectionStatus.bind(this);

        this.editObjectionClick = this.editObjectionClick.bind(this);

    }

    changedLinkedId(event)
    {
        let object_id = event.target.value;
        this.setState({linked_id:object_id, link_status:this.getObjectionStatus(object_id), completion_date:null});
    }

    changedLinkedStatus(event)
    {
        let status = event.target.value
        let completion_date = null;

        if ((status === "NO_MERIT" || status === "ACTION_COMPLETED") && this.state.linked_id!=null && status !== this.getObjectionStatus(this.state.linked_id))
        {
            completion_date = moment().add(4, 'weeks').format("YYYY-MM-DD");
        }
        this.setState({link_status:status, completion_date:completion_date});
    }
    onSelected(eventKey, event)
    {
        let key = String(eventKey);

        if (key === "new")
        {
            this.setState({isNewObjection:true, title:"", description:""});
        }
        else if (key === "lnk")
        {
            this.setState({isLinkObjection:true});
        }
    }

    clearState()
    {
        this.setState({isNewObjection:false, isLinkObjection:false,isEditObjection:false, title:"", status:"ASSESSING",desc:"", linked_id:null, completion_date:null, new_status:null,editObjection: null});
    }
    saveObjection()
    {
        let title = this.state.title;
        let desc = this.state.desc;
        let status = this.state.status;
        let chapter = this.state.chapter;


        if (this.state.isNewObjection) {
            this.props.newObjection({name: title, chapter: chapter, desc: desc, status: status});
        }else if (this.state.isEditObjection){
            let objection = this.state.editObjection;
            this.props.updateObjection(objection?.object_id, {name: title, chapter: chapter, desc: desc});
        }

        this.clearState();
    }

    linkNewObjection()
    {
        let objection_id = this.state.linked_id;
        let new_status = this.state.link_status;
        let completion_date = this.state.completion_date;

        this.props.newObjection({objection_id:objection_id,status:new_status, completion_date:completion_date});

        this.clearState();
    }

    viewObjection(objection_id)
    {
        this.props.viewObjection(objection_id);
    }

    getObjectionStatus(object_id)
    {
        // console.log("getObjectionStatus " + object_id);
        // console.log(this.state);
        if(object_id === null || object_id === undefined){
            return "Unknown";
        }
        try {
            let o = this.props.objectLists["StakeholderObjection"][object_id];
            let status = envid.getObjectField(o, "extra");
            if (status == null)
            {
                status = envid.getObjectField(o, "status");
            }
            return status;
        }
        catch (e) {console.log(e);}
        return "Unknown";
    }

    editObjectionClick(objection){
        const details = objection.details;
        this.setState({isEditObjection: true, title:details.name,status: details.status, chapter: details.chapter, desc: details.desc?.text,editObjection: objection})
    }

    render()
    {
        const {t} = this.props;

        // console.log("StakeholderEventObjectionsView:: ",this.props);
        let objections = [];
        if (this.props.objectionLinks != null)
        {
            objections = this.props.objectionLinks;
        }

        let allObjections = [];
        try
        {
            allObjections = Object.values(this.props.objectLists["StakeholderObjection"]);
        } catch (e) {}

        allObjections = allObjections.filter((o) => o.published===true || (o.publishingInfo != null && o.publishingInfo.published===true));


        let addButton = null;
        if (!this.props.published) {

            let items = [
                <MenuItem key="new" style={{marginLeft:"-12px"}} eventKey="new">{t("Raise New Objection")}</MenuItem>,
            ];

            if (allObjections != null && allObjections.length > 0)
            {
                items.push(<MenuItem key="lnk" style={{marginLeft:"-12px"}} eventKey="lnk">{t("Link Existing Objection")}</MenuItem>);
            }

            addButton = (
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <DropdownButton id="new-objection-dropdown" className="btn btn-primary" style={{background:"#1ab394", color:"white"}} title={<span><i className="fa fa-plus"></i> {t("Add Objection")}</span>} onSelect={this.onSelected}>
                        {items}
                    </DropdownButton>
                </div>
            );
        }

        let modalTitle = this.state.isEditObjection ? t("Edit Objection") : t("Raise New Objection");
        let statusLabel = this.state.isEditObjection? t("Status") : t("Initial Status");

        let newObjectionModal = (
            <Modal show={this.state.isNewObjection || this.state.isEditObjection} dialogClassName="objection-modal" onHide={this.clearState.bind(this)}>
                <ModalHeader closeButton>
                    <ModalTitle>
                        <EnvidObjectIcon type="Objection"/>&nbsp;&nbsp;{modalTitle}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <FormGroup key="status">
                        <ControlLabel>{statusLabel}</ControlLabel>
                        <FormControl componentClass="select"
                                 placeholder="select"
                                 value={this.state.status}
                                 disabled={this.state.isEditObjection}
                                 onChange={(event)=> this.setState({status:event.target.value})}>
                            <option value="ASSESSING">{t("Assessing")}</option>
                            <option value="MERIT">{t("Has Merit")}</option>
                            <option value="NO_MERIT">{t("No Merit")}</option>
                        </FormControl>
                    </FormGroup>

                    <FormGroup key="name">
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <div>
                            <FormControl type='text' field="name"
                                         onChange={(event) => this.setState({title:event.target.value})}
                                         style={{width:"100%", fontSize:"13px"}}
                                         value={this.state.title}/>
                        </div>
                    </FormGroup>

                    <FormGroup key="chapter">
                        <ControlLabel>{t("Chapter")}</ControlLabel>
                        <div>
                            <FormControl type='text' field="name"
                                         onChange={(event) => this.setState({chapter:event.target.value})}
                                         style={{width:"100%", fontSize:"13px"}}
                                         value={this.state.chapter}/>
                        </div>
                    </FormGroup>

                    <FormGroup key="desc">
                        <ControlLabel>{t("Description")}<br/></ControlLabel>
                        <div>
                            <Editor model={this.state.desc}
                                    handleModelChange={(model) => {this.setState({desc:model})}}
                                    off={true}
                            />
                        </div>
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.clearState.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="done" onClick={this.saveObjection.bind(this)}>{t("Save")}</EButton>
                </ModalFooter>

            </Modal>
        );


        //console.log("All Objections");
        //console.log(allObjections);


        let linkObjectionModal = (
            <Modal show={this.state.isLinkObjection} onHide={this.clearState.bind(this)}>
                <ModalHeader closeButton>
                    <ModalTitle>
                        <EnvidObjectIcon type="Objection"/>&nbsp;&nbsp;{t("Link Existing Objection")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <FormGroup key="linked_id">
                        <ControlLabel>{t("Select Objection to Link to")}</ControlLabel>
                        <FormControl componentClass="select"
                                     placeholder="select"
                                     value={this.state.linked_id}
                                     onChange={this.changedLinkedId.bind(this)}>
                            <option key="default" value="">{t("Select an Existing Objection")}</option>
                            {
                                allObjections.map((o) => {
                                    //console.log(o);
                                  return (<option key={o.object_id} value={o.object_id}>{envid.getObjectField(o, "name")}</option>)
                                })
                            }
                        </FormControl>
                    </FormGroup>

                    <FormGroup key="old_status">
                        <ControlLabel>{t("Current Status")}</ControlLabel>
                        <div>
                        <ObjectionStatusLabel status={this.getObjectionStatus(this.state.linked_id)}/>
                        </div>
                    </FormGroup>

                    <FormGroup key="new_status">
                        <ControlLabel>{t("New Status")}</ControlLabel>
                        <FormControl componentClass="select"
                                     placeholder="select"
                                     value={this.state.link_status}
                                     onChange={this.changedLinkedStatus.bind(this)}>
                            <option value="ASSESSING">{t("Assessing")}</option>
                            <option value="MERIT">{t("Has Merit")}</option>
                            <option value="NO_MERIT">{t("No Merit")}</option>
                            <option value="ACTION_COMPLETED">{t("Action Completed")}</option>
                            <option value="SATISFIED">{t("Satisfied")}</option>
                        </FormControl>
                    </FormGroup>

                    <FormGroup key="completion_date" style={{display:(this.state.completion_date==null) ? "none" : ""}}>
                        <ControlLabel>{t("Satisfied On")}</ControlLabel>
                        <FormControl type='date' field="details.timestamp"
                                     onChange={(event) => this.setState({completion_date:event.target.value})}
                                     value={this.state.completion_date}/>
                    </FormGroup>


                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.clearState.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="done" disabled={this.state.linked_id == null} onClick={this.linkNewObjection.bind(this)}>{t("Save")}</EButton>
                </ModalFooter>

            </Modal>
        );

        let rows = [];
        let objectionTable = null;

        // console.log("objectLists",this.props.objectLists);
        rows = objections.map((objectionLink) => {
            // console.log("Objection = ",objectionLink);
            //console.log(objectionLink);

            let objection = envid.getObjectFromLink(this.props.objectLists, objectionLink, "StakeholderObjection");

            // console.log("objection ",objection);
            let status = envid.getObjectField(objection, "extra");
            if (status == null)
            {
                status = envid.getObjectField(objection, "status");
            }
            let link_status = envid.getObjectField(objectionLink, "new_status");

            let button = null;
            let editButton = null;

            if (!this.props.readOnly) {
                editButton = (
                    <EButton type="edit" isTable={true} onClick={() => this.editObjectionClick(objection)} >{t('Edit')}</EButton>
                );

                if (!this.props.published) {
                    button = <EButton type="remove" isTable={true}
                                      onClick={() => this.props.removeObjection(objectionLink.object_id)}>
                        Remove
                    </EButton>
                } else {
                    button = <EButton type="view" isTable={true}
                                      onClick={this.viewObjection.bind(this, objection.object_id)}>
                        {t('View')}
                    </EButton>
                }
            }

            return (
                <tr key={objectionLink.object_id}>
                    <td><ObjectionStatusLabel status={status}/></td>
                    <td>{envid.getObjectField(objection, "name")}</td>
                    <td><ObjectionStatusLabel status={link_status} plain={true}/></td>
                    <td>{button}</td>
                    <td>{editButton}</td>
                </tr>);
        });

        if (rows.length > 0)
        {
            objectionTable = (
                <Table className="table table-hover">
                    <thead>
                        <tr>
                            <th>{t('Current Status')}</th>
                            <th>{t('Description')}</th>
                            <th>{this.props.published ? t("Status Changed to") : t("New Status")}</th>
                            <th style={{width:1}}></th>
                            <th style={{width:1}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            );
        } else {
            objectionTable = <div>{t("No Objections, you can raise a new objection or link to an existing objection")}</div>
        }

        let body = (
            <div>
                {newObjectionModal}
                {linkObjectionModal}
                {objectionTable}
            </div>);

        return (
            <div>
                <Row>
                    <Col md={2}><EventSectionTitle><EnvidObjectIcon type="Objection"/>&nbsp;&nbsp;&nbsp;{t("Objections")}</EventSectionTitle></Col>
                    <Col md={10}>{this.props.readOnly ? [] : addButton}</Col>
                </Row>
                <div>
                    {body}
                </div>
            </div>);
    }
}

export default withTranslation()(StakeholderEventObjectionsView)
