/**
 * Created by lewellyn on 29/11/16.
 */
import React from 'react';
import {FormControl, ControlLabel} from 'react-bootstrap'
import {withTranslation} from "react-i18next";

class ReportInputAnswer extends React.Component{


    constructor(props){
        super(props);

        this.state = {text:props.answer.reason, status:props.answer.answer};

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(event)
    {

        let text = (event.target.value==null) ? null : event.target.value.toString();

        let status = "New";
        if (text == null || text === "")
        {
            status = "DontKnow";
        } else {
            status = "Yes"
        }

        this.setState({text:text, status:status});
    }

    onBlur(event)
    {
        let answer = Object.assign({}, this.props.answer, {reason:this.state.text, answer:this.state.status});
        this.props.onSave(answer);
    }

    render() {

        const {t} = this.props;

        console.log("ReportInput Answer");
        console.log(this.props);

        let icon = null;
        if (this.state.text == null || this.state.text === "") {
            icon = <i className="fa fa-times text-warning"/>;
        } else {
            icon = <i className="fa fa-check text-navy"/>;
        }

        return (
            <div>
                <ControlLabel>{t("Input")} {icon}</ControlLabel>
                {this.props.readOnly ?
                    <div style={{fontWeight: 200, fontSize: 12}}>{this.state.text}</div>
                    :


                    <FormControl type='text'
                                 onChange={this.onChange}
                                 onBlur={this.onBlur}
                                 style={{width: "90%", fontSize: "13px"}}
                                 placeholder={t("Enter your answer")}
                                 value={this.state.text}/>
                }
            </div>
        );
    }
}

export default withTranslation()(ReportInputAnswer)
