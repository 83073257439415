import React from 'react';
import sitedata from '../utils/sitedata'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import PropTypes from 'prop-types';

import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import {Table} from 'react-bootstrap'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import taskUtil from "../utils/taskUtil"
import HubHeader from "../components/common/HubHeader"
import HubBox from "../components/common/HubBox"
import envid from "../utils/envid"
import ProjectSidebarWrapper from "../components/common/ProjectSidebarWrapper"
import {ManageReportsViewContainer, ManageReportsModalContainer} from './ManageReportsView'
import SessionManager from "../utils/SessionManager";
import {Trans, withTranslation} from "react-i18next";

class ReportHomeView extends React.Component {

    constructor()
    {
        super();
        this.state = {showHelp:true, showReportTemplates:false};

    }

    showTask(type, action)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        if (action === "DefaultYes" || action === "DefaultNo" || action === "ClearAll")
        {
            // we could go to the task, but i think we'll just stay on the summary screen for now.
            let whenfinished = () => {};//this.props.routerPush.bind(this, "/report-defn/" + project_id + "/task/" + type);
            this.props.performTaskAction(project_id, type, action, whenfinished);
        }
        else
        {
            // this.props.routerPush("/report-defn/" + project_id + "/task/" + type);
            SessionManager.shared().goto("/report-defn/" + project_id + "/task/" + type);
        }
    }
    editProject()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        // this.props.routerPush("/projects/project/" + project_id+'/info');
        SessionManager.shared().goto("/projects/project/" + project_id+'/info');
    }


    render() {

        const {t} = this.props;

        let page_title = t("Assess Impacts & Risks");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;

        if (this.props.isLoading || this.props.report == null)
        {
            let p = null;
            if (this.props.projectName != null)
            {
                p = {name:this.props.projectName};
            }

            return <div>
                        <ContainerHeader title={this.props.makeTitle(p, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', page_title]} dimensions={this.props.dimensions}/>
                        <PageWrapper dimensions={this.props.dimensions}>
                            <Spinner/>
                        </PageWrapper>
                    </div>
        }

        let report = this.props.report;
        let project = this.props.project;

        var navs = [];

        var total_percentage = 0;

        navs = [];
        let titleStyle = {fontSize:"larger", fontWeight:"normal", borderBottom:"1px solid #DDD", marginTop:-10, paddingBottom:15}; // borderBottom:"2px solid black"

        for (let i=0; i<taskUtil.task_items.length; ++i)
        {
            let item = taskUtil.task_items[i];
                    // var link = item.link;


                    let status = report.summary[item.type];
                    let label = "-warning";
                    let label_text = "";
                    let completion = "";
                    let byline = null;
                    let click = this.showTask.bind(this, item.type);
                    let actions = "";
                    //let actionsX = <EButton type="edit" isTable={true} onClick={click}>Edit</EButton>

                    let onSelected = function(eventKey, event) { click(eventKey); }

                    if(!this.props.readOnly) {
                        let items = [];
                        if (item.type === "AspectActivity" || item.type === "Receptor" || item.type === "Impact") {
                            items.push(<MenuItem key={"DefaultYes" + i} eventKey="DefaultYes">{t("Default to Yes")}</MenuItem>);
                            items.push(<MenuItem key={"DefaultNo" + i} eventKey="DefaultNo">{t("Default to No")}</MenuItem>);
                            items.push(<MenuItem key={"ClearAll" + i} eventKey="ClearAll">{t("Clear All")}</MenuItem>);
                        }
                        else if (item.type === "Control") {
                            items.push(<MenuItem key={"DefaultYes" + i} eventKey="DefaultYes">{t("Default to Yes")}</MenuItem>);
                            items.push(<MenuItem key={"ClearAll" + i} eventKey="ClearAll">{t("Clear All")}</MenuItem>);
                        }
                        else if (item.type === "Risk") {

                        }
                        items.push(<MenuItem key={"Edit" + i} eventKey="Edit">{t("Edit")}</MenuItem>);

                        actions = <DropdownButton key={"Action-" + i} className="btn btn-default sm default"
                                                      title={t("Action")} onSelect={onSelected}>
                            {items}
                        </DropdownButton>
                    }


            let unknown = null;
                    let bar_colour = "";
                    if (status != null && (status.status === "Completed" || status.status === "Pending"))
                    {
                        if (status.unknown_answers != null && status.unknown_answers > 0)
                        {
                            unknown = <span className="label label-warning">{status.unknown_answers} {t("Unknown Answers")}</span>
                            bar_colour = "#f8ac59";
                        } else {
                            unknown = <span className="label label-primary">{status.unknown_answers} {t("Unknown Answers")}</span>
                        }
                    }

                    if (status == null)
                    {
                        label_text = t("Unknown");
                        label = "";
                        actions = null;
                    }
                    else if (status.status === "Completed")
                    {
                        label = "-primary";
                        label_text = t("Complete");
                        completion = [
                            <small>{t("Completed")} {status.percentage+"%"}</small>,
                            <div className="progress progress-mini">
                                <div style={{width:status.percentage+"%", background:bar_colour}} className="progress-bar"></div>
                            </div>
                            ];
                        if (status.document_info != null && status.document_info.last_update_time != null)
                        {
                            byline = t("Completed") + " " + new Date(status.document_info.last_update_time).toDateString();
                        } else {
                            byline = t("Completed") + " ";

                        }

                    }
                    else if (status.status === "Unavailable")
                    {
                        label = "-default";
                        label_text = t("Not Ready");
                        actions = null;
                        click = null;
                        completion = [<small>{t("Waiting for completion of")}</small>,
                                       <br/>];
                        if (status.waitingFor != null && status.waitingFor.length > 0)
                        {

                            for (let i=0; i<status.waitingFor.length; ++i)
                            {
                                let s = status.waitingFor[i];
                                let itm = taskUtil.findTaskItem(s);
                                if (itm != null)
                                {
                                    let comma = (i===0)?"":" & ";
                                    completion.push(<small>{comma}<EnvidObjectIcon type={itm.icon}/>&nbsp;{t(itm.name)}</small>)
                                }
                            }

                        }
                    }
                    else if (status.status === "Empty")
                    {
                        label = "-danger";
                        label_text = t("Empty");
                        actions = null;
                        click = null;
                        completion = <Trans i18nKey="task-empty"><span>This task is empty<br/>Please review your answers to previous tasks</span></Trans>
                    }
                    else if (status.status === "New") {
                        label = "-success";
                        label_text = t("Ready")
                        byline = t("Ready for input");
                        completion = [
                            <small>Click to start</small>,
                            <div className="progress progress-mini">
                                <div style={{width:status.percentage+"%", background:bar_colour}} className="progress-bar"></div>
                            </div>
                        ];
                    }
                    else if (status.status === "Pending")
                    {
                        label = "-warning";
                        label_text = t("In Progress")
                        completion = [
                            <small>{t("Completed")} {status.percentage+"%"}</small>,
                            <div className="progress progress-mini">
                                <div style={{width:status.percentage+"%"}} className="progress-bar"></div>
                            </div>
                        ];
                        if (status.document_info != null && status.document_info.last_update_time != null)
                        {
                            byline = t("Completed") + " " + new Date(status.document_info.last_update_time).toDateString();
                        } else {
                            byline = t("Completed") + " ";

                        }

                        byline = t("Last Edit") + " " + new Date().toDateString();
                    }
                    else
                    {
                        label_text = status.status;
                    }

                    let project_status_width = "15%";//"130px";
                    let project_completion_width = "30%";//"250px";
                    let project_action_width = "15%";//"100px";


                    // if (i==0)
                    // {
                    //     navs.push(<tr style={titleStyle}><td colSpan="10" style={{paddingBottom:5, paddingTop:20}}>Establish the Context</td></tr>)
                    // } else if (i == 3) {
                    //     navs.push(<tr style={titleStyle}><td colSpan="10" style={{paddingBottom:5, paddingTop:40}}>Impact & Risk Assessment</td></tr>)
                    // } else if (i == 5) {
                    //     navs.push(<tr style={titleStyle}><td colSpan="10" style={{paddingBottom:5, paddingTop:40}}>Impact & Risk Treatment</td></tr>)
                    // }
                    navs.push(
                        <tr key={i}>
                            <td className="project-status" style={{width:project_status_width}} onClick={click}>
                                <span className={"label label" + label}>{label_text}</span>
                            </td>
                            <td className="project-title" onClick={click}>
                                <EnvidObjectIcon type={item.icon}/>&nbsp;&nbsp;<strong>{t(item.name)}</strong>
                                <br/>
                                <small>{byline}</small>
                            </td>
                            <td className="project-completion" style={{width:project_completion_width}} onClick={click}>
                                {completion}
                            </td>

                            <td className="project-actions" style={{width:project_action_width}}>
                                {actions}
                            </td>
                        </tr>
                );

            if (status != null && status.percentage != null) {
                total_percentage += status.percentage;
            }
        }



        total_percentage = Math.round(total_percentage);
        // var help = null;
        // let project_title = project.name;

        let names = [t("Provide the Context"), t("Assess Impacts"), t("Select Controls"), t("Manage Risk"), t("Generate Reports")];
        let status = [];
        // Work out the status for each hub box

        // Provide Context Status
        status.push(taskUtil.combineStatus([report.summary["AspectActivity"], report.summary["Receptor"], report.summary["TechnicalInputs"]]));

        // Assess Impacts
        status.push(taskUtil.combineStatus([report.summary["Impact"], report.summary["EnvironmentalInputs"]]));

        // Select Controls
        status.push(taskUtil.combineStatus([report.summary["Control"]]));

        // Risk
        status.push(taskUtil.combineStatus([report.summary["Risk"]]));

        // Reports
        status.push("Ready");

        let box_header = (
            <HubHeader onSelect={(index) => SessionManager.shared().goto("/report-defn/" + project_id + "/summary/" + index)} selected={params.tabIndex}>
                <HubBox name={names[0]} colour={envid.getObjectColours("Context")} icon={envid.getObjectIcons("Context")} status={status[0]} hasPoint={true}    key={0}/>
                <HubBox name={names[1]} colour={envid.getObjectColours("Impact")}   icon={envid.getObjectIcons("Impact")} status={status[1]} hasPoint={true}    key={1}/>
                <HubBox name={names[2]} colour={envid.getObjectColours("Control")}  icon={envid.getObjectIcons("Control")} status={status[2]} hasPoint={true}   key={2}/>
                <HubBox name={names[3]} colour={envid.getObjectColours("Risk")}   icon={envid.getObjectIcons("Risk")} status={status[3]} hasPoint={true}        key={3}/>
                <HubBox name={names[4]} colour={envid.getObjectColours("Report")}   icon={envid.getObjectIcons("Report")} status={status[4]} hasPoint={false}   key={4}/>
            </HubHeader>
        );
        let selected_block = null;
        let tabIndex = parseInt(params.tabIndex);
        if (tabIndex >= 0 && tabIndex <=3)
        {
            let i_start = 0;
            let i_end = 2;


            switch (tabIndex)
            {
                case 0: i_start = 0; i_end = 3; break;
                case 1: i_start = 4; i_end = 4; break;
                case 2: i_start = 5; i_end = 5; break;
                case 3: i_start = 6; i_end = 6; break;
            }

            selected_block = (
                    <div className="ibox float-e-margins">
                        <div className="ibox-content p-md">

                            <div style={titleStyle}><span style={{paddingBottom:5, paddingTop:20}}>{names[tabIndex]}</span></div>
                            <div className="project-list">
                                <Table className="table table-hover">
                                    <tbody>
                                    {navs.filter((row, i) => i>= i_start && i<= i_end)}
                                    </tbody>
                                </Table>
                            </div>

                        </div>

                    </div>);

        }
        else if (tabIndex === 4)
        {
            selected_block = <ManageReportsViewContainer readOnly={this.props.readOnly} showReportTemplates={(b) => this.setState({showReportTemplates:b})}/>
        }
        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+"/info", page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>

                    <ManageReportsModalContainer showReportTemplates={(b) => this.setState({showReportTemplates:b})}
                                                 show={this.state.showReportTemplates}
                    />

                    <ProjectSidebarWrapper project={project} editProject={this.editProject.bind(this)} allowEdit={!this.props.readOnly}>
                        {box_header}
                        {selected_block}
                    </ProjectSidebarWrapper>

                </PageWrapper>
            </div>
        )
    }

}

ReportHomeView.propTypes = {
    report : PropTypes.object,
    project : PropTypes.object,
    isLoading : PropTypes.bool,
};


export default withTranslation()(ReportHomeView)
