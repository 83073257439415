import React from 'react';
import {FormGroup} from 'react-bootstrap'

import 'icheck/skins/all.css'
import {Checkbox} from 'react-icheck'
import details from '../utils/details'
import EnvidObjectIcon from '../components/common/EnvidObjectIcon'
import values from "object.values"
import {SummaryTree, SummaryItem} from "../components/common/SummaryTree"
import {withTranslation} from "react-i18next";


const Tag = function(props)
{
    if (props.children === "Not Selected")
    {
        return <span>{props.children}</span>
    }

    let colour = "primary";
    if (props.ranking !== undefined)
    {
        switch (props.ranking)
        {
            case 0: colour = 'danger'; break;
            case 1: colour = 'warning'; break;
            default: colour = 'primary'; break;
        }
    }
    return <span className={"label label-"+colour} style={{fontSize:11}}>{props.children}</span>
}

const ReadOnlyLabel = function(props)
{
    return <div style={{fontWeight: 200, fontSize: 24}}>{props.children}</div>
}
/**
 * Component for rendering the details of an object. Uses a template to render, then uses it's container to actually
 * do the saving work
 *
 * props
 *      currentObject : the current object for showing details of. It has various elements inside it, like object, etc
 *      fields : an object containing all the field definitions, this comes from the templates defined in details.js
 */
class DataDetailsReadOnly extends React.Component {

    renderObject(object)
    {
        let style_small_space = {marginRight:"5px"};

        let ret = [];

        let type = object.type;
        if (object.displayed_object_type != null) type = object.displayed_object_type;
        ret.push(<EnvidObjectIcon type={type} showName={false}/>);
        ret.push(<span style={style_small_space}/>);
        ret.push(object.name);

        return <span className="tree-item" style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{ret}</span>
    }

    readName(obj)
    {
        if (obj.name != null)
        {
            return obj.name;
        }
        else if (obj.details != null && obj.details.name != null)
        {
            return obj.details.name;
        }
        return "";
    }

    render() {

        const {t} = this.props;

        console.log("DataDetailsReadOnly:: Render");
        console.log(this.props);
        console.log('type ',this.props.currentObject.object.type);
        console.log("-----------------");

        let type = this.props.currentObject.object.type;
        let obj = this.props.currentObject.object;
        let obj_id = obj.object_id;

        if (!Object.values) values.shim();

        if (type == null)
        {
            return (<div className="error">{t("No object type found")}</div>);
        }

        let template = this.props.fields;

        if (template == null)
        {
            return (<div className="error">{t("Don't know how to display")} {type}</div>);
        }

        var style = {marginBottom:"20px"};

        var nextCount = 0;
        var nextCountValue = false;
        var nextCountField = null;

        let beforeName;//content shown below the icon but above the object name.
        var fields = [];
        let firstValue = null;

        for (var i in template) {

            var tpt = template[i];
            if (tpt.ignore === true) continue;

            let value = details.getExtendedValue(this.props.currentObject, tpt.field);

            // we don't want the very first one because that is the name
            if (i===0) {
                firstValue = value;
                continue;
            }

            //The ImpactRecptorLink likes to be different and display
            //the receptor info before the name.
            if (i === 1 && type === "ImpactReceptorLink"){

                beforeName = (
                    <FormGroup key={"readonly_"+tpt.field+"_"+tpt.type} style={style}>
                        <ReadOnlyLabel >{tpt.display}</ReadOnlyLabel>
                        <div className="ebase_editor-theme">
                            <div dangerouslySetInnerHTML={{__html: (value == null) ? t("No Description") : value}}></div>
                        </div>
                    </FormGroup>
                )
                continue;
            }

            let fgClass = "";//"row";
            let labelClass = "";//"col-lg-2 text-right";
            let inputClass= "";//"col-lg-9";
            let inputStyle = {};//{marginLeft:"30px", marginRight:"30px"};
            if (tpt.type === "text") {
                fields.push(
                    (
                        <FormGroup key={tpt.field} className={fgClass} style={style}>
                            <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                            <div className={inputClass} style={inputStyle}>{value}</div>
                        </FormGroup>
                    )
                );
            }
            else if (tpt.type === "link-to") {
                let type = this.props.currentObject.object.type;

                //envid.getObjectIcons returns the Activity Icon.
                //For link-to we want it to be the project category icon.
                if (type === "ProjectActivityLink"){
                    type = "ProjectCategory";
                }
                fields.push(<h3 key={tpt.field} style={{marginTop:30}}>{t('Linked to')} <EnvidObjectIcon type={type}/>&nbsp;&nbsp;{value}</h3>);
            }
            else if (tpt.type === "control-tree") {

                let fullname = this.readName(obj);

                var items;
                items = this.props.currentObject.objectLists["ControlName"];

                // var objectType = tpt.type;

                if (items === undefined || items === null) {
                    items = [];
                }

                let control = Object.values(items).filter((r) => r.object_id === value).map((r) => this.readName(r));
                control = (control.length === 0)?t("None"):control[0];

                // split the name
                let pos = fullname.indexOf(" caused by ");
                let impact = (pos<0)? t("None") : fullname.substring(control.length+5, pos);
                let rest = (pos<0)? t("None") : fullname.substring(pos+11);

                pos = rest.lastIndexOf(" from ");
                // let activity = (pos<0)? t("None") : rest.substring(pos+6);
                let aspect = rest;//(pos<0)? "None" : rest.substring(0, pos);


                fields.push(
                    <div key={tpt.field}>
                        <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                        <div style={{marginLeft:20, marginTop:10}}>
                            <SummaryTree renderObject={this.renderObject}>
                                    <SummaryItem object={{object_id:"2", type:"Aspect", name:aspect}}>
                                        <SummaryItem object={{object_id:"2", type:"Impact", name:impact}}>
                                            <SummaryItem object={{object_id:"3", type:"Control", name:control}}></SummaryItem>
                                        </SummaryItem>
                                    </SummaryItem>
                            </SummaryTree>
                        </div>
                    </div>
                );
                // fields.push(
                //     <div>
                //         <ReadOnlyLabel className={labelClass}>{t.display}</ReadOnlyLabel>
                //         <div style={{marginLeft:20, marginTop:10}}>
                //             <SummaryTree renderObject={this.renderObject}>
                //                 <SummaryItem object={{object_id:"1", type:"Activity", name:activity}}>
                //                     <SummaryItem object={{object_id:"2", type:"Aspect", name:aspect}}>
                //                         <SummaryItem object={{object_id:"2", type:"Impact", name:impact}}>
                //                             <SummaryItem object={{object_id:"3", type:"Control", name:control}}></SummaryItem>
                //                         </SummaryItem>
                //                     </SummaryItem>
                //                 </SummaryItem>
                //             </SummaryTree>
                //         </div>
                //     </div>
                // );
            }
            else if (tpt.type === "impact-tree")
            {
                let fullname = this.readName(obj);

                var items;
                items = this.props.currentObject.objectLists["Aspect"];

                // var objectType = tpt.type;

                if (items === undefined || items === null) {
                    items = [];
                }

                //let impactObj = Object.values(items).filter((r) => r.object_id == value).map((r) => r.name);
                //impactObj = (impactObj.length == 0)?"None":impactObj[0];

                // split the name
                let pos = fullname.indexOf(" caused by ");
                let impact = (pos<0)? t("None") : fullname.substring(0, pos);
                let rest = (pos<0)? t("None") : fullname.substring(pos+11);

                pos = rest.lastIndexOf(" from ");
                // let activity = (pos<0)? t("None") : rest.substring(pos+6);
                let aspect = rest;//(pos<0)? "None" : rest.substring(0, pos);


                fields.push(
                    <div key={tpt.field}>
                        <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                        <div style={{marginLeft:20, marginTop:10}}>
                            <SummaryTree renderObject={this.renderObject}>
                                    <SummaryItem object={{object_id:"1", type:"Aspect", name:aspect}}>
                                        <SummaryItem object={{object_id:"2", type:"Impact", name:impact}}>
                                        </SummaryItem>
                                    </SummaryItem>
                            </SummaryTree>
                        </div>`
                    </div>
                );
            }

            else if (tpt.type === "readonlytext") {
                fields.push(
                    (
                        <FormGroup key={tpt.field} className={fgClass} style={style}>
                            <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                            <div className={inputClass} style={inputStyle}>{value}</div>
                        </FormGroup>
                    )
                );
            }
            else if (tpt.type === "ftext")
            {
                inputClass += " ebase_editor-theme"
                let field = (
                    <FormGroup key={"readonly_"+tpt.field+"_"+tpt.type} className={fgClass} style={style}>
                        <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                        <div className={inputClass} style={{...inputStyle}}>

                            <div dangerouslySetInnerHTML={{__html: (value == null) ? t("No Description") : value}}></div>



                        </div>
                    </FormGroup>
                );


                if (nextCount === 2)
                {
                    nextCountField = field;
                    --nextCount;
                }
                else if (nextCount === 1)
                {
                    nextCount = 0;
                    if (value == null || value.length === 0 || nextCountValue === true)
                    {
                        field = nextCountField;
                    }
                    fields.push(field);
                }
                else
                {
                    fields.push(field);
                }

            }
            else if (tpt.type === "bool") {
                if (value == null) value = false;

                    let check = (
                        <FormGroup key={tpt.field} style={style}>
                            <div className={inputClass} style={inputStyle}>
                                <Checkbox
                                          checked={value}
                                          checkboxClass="icheckbox_square-green"
                                          label={"  " + tpt.display}
                                >
                                </Checkbox>
                            </div>
                        </FormGroup>
                    );

                    fields.push(check);
            }
            else if (tpt.type === "bool-next")
            {
                if (value == null) value = false;
                nextCountValue = value;
                nextCount = 2;
            }
            else if (tpt.type === "EnvironmentalInput" || tpt.type === "TechnicalInput")
            {
                let inputs = this.props.currentObject.objectLists[tpt.type];
                // let current_list = value;
                var options = [];

                if (inputs === null || inputs === undefined) inputs = [];

                if (!Object.values) values.shim();

                options = Object.values(inputs)
                    .sort((r1, r2) => this.readName(r1).localeCompare(this.readName(r2)))
                    .map((r) => {
                        return <li>{this.readName(r)}</li>;
                    });

                // value should be a list
                fields.push(
                    (
                        <div key={tpt.field} style={{}}>
                        <FormGroup  className={fgClass} style={style}>
                        <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                        <div className={inputClass} style={inputStyle}>
                            <ul className="todo-list m-t small-list">
                            {options}
                            </ul>
                        </div>

                        </FormGroup>
                         </div>
                    )
                );
            }

            // else if (t.type == "Impact")
            // {
            //     <SummaryTree>
            //         <SummaryItem>
            //             <SummaryItem/>
            //         </SummaryItem>
            //     </SummaryTree>
            // }

            /**
             * A Select that lets you choose the object by typing a partial value
             */
            else if (tpt.type === "AspectActivityLink" || tpt.type === "ImpactReceptorLink" ||
                     tpt.type === "Receptor" || tpt.type === "Impact" || tpt.type === "Region" || tpt.type === "ProjectCategory")
            {
                var items;
                items = this.props.currentObject.objectLists[tpt.type];
                // console.log("project categories");
                // console.log(items);
                let isMulti = tpt.type === "ProjectCategory";

                if (items === undefined || items === null) {
                    fields.push(
                        <FormGroup key={tpt.field} className={fgClass} style={style}>
                            <ReadOnlyLabel className={labelClass}><EnvidObjectIcon type={tpt.type} showName={false}/>&nbsp;&nbsp;&nbsp;{tpt.display}</ReadOnlyLabel>
                            <div className={inputClass} style={inputStyle}>No {tpt.type} Defined</div>
                        </FormGroup>
                    );
                } else {

                    let finalValue = null;
                    if (isMulti)
                    {
                        // console.log("Is Multy");
                        let lis = [];
                        if(tpt.type === "ProjectCategory") {
                            // let cats = Object.values(items);
                            let selectedCategories = this.props.currentObject.object.details.project_categories;

                            for (let k = 0; k < selectedCategories.length; k++) {
                                // console.log(cats[k]);
                                let cat = items[selectedCategories[k]];

                                lis.push(<li>{cat.name}</li>)
                            }
                        }

                        finalValue = <ul>{lis}</ul>
                    }
                    else
                    {
                        finalValue = Object.values(items).filter((r) => r.object_id === value).map((r) => <p>{this.readName(r)}</p>)
                    }

                    fields.push(
                        (
                            <FormGroup key={tpt.field} className={fgClass} style={style}>
                                <ReadOnlyLabel className={labelClass}><EnvidObjectIcon type={tpt.type} showName={false}/>&nbsp;&nbsp;&nbsp;{tpt.display}</ReadOnlyLabel>
                                <div className={inputClass} style={inputStyle}>

                                    {finalValue}

                                </div>
                            </FormGroup>

                        )
                    );
                }
            }
            /**
             * Lets you choose a value, or add a new one
             */
            else if (tpt.type === "ImpactType" ||
                     tpt.type === "ControlName" || tpt.type === "ControlSource")
            {
                var items;
                items = this.props.currentObject.objectLists[tpt.type];

                // var objectType = tpt.type;

                if (items === undefined || items === null) {
                    items = [];
                }

                let finalValue = Object.values(items).filter((r) => r.object_id === value).map((r) => <Tag>{this.readName(r)}</Tag>);

                fields.push(
                    (
                        <FormGroup key={tpt.field} className={fgClass} style={style}>
                            <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                            <div className={inputClass} style={inputStyle}>
                                {finalValue}
                            </div>
                        </FormGroup>

                    )
                );
            }
            else if (tpt.type === "ControlRanking")
            {
                let options = ["Mandatory", "Recommended", "Discretionary"];
                fields.push(
                    (
                        <FormGroup key={tpt.field} className={fgClass + " well"} style={{...style, display:"inline-block"}}>
                            <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                            <div className={inputClass} style={inputStyle}>
                                <Tag ranking={value}>{(value===0||value===1||value===2) ? t(options[value]) : t("Not Selected")}</Tag>
                            </div>
                        </FormGroup>

                    )
                );

            }
            /**
             * Just a plan old orfinary drop down will do for some
             */
            else if (tpt.type === "ReceptorClassification" || tpt.type === "ControlType")
            {
                var receptors;
                receptors = this.props.currentObject.objectLists[tpt.type];

                if (receptors === undefined || receptors === null || value == null || value.length===0) {
                    fields.push(

                        <div key={tpt.field} className={fgClass + " well"} style={{...style, display:"inline-block", marginRight:15}}>
                            <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                                <div className={inputClass} style={inputStyle}>{t('Not Defined')}</div>
                        </div>
                    );
                } else {

                    let finalValue = Object.values(receptors).filter((r) => r.object_id === value).map((r) => <Tag>{t(this.readName(r))}</Tag>);

                    fields.push(
                        (
                            <div key={tpt.field} className={fgClass + " well"} style={{...style, display:"inline-block", marginRight:15}}>
                                <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                                <div className={inputClass} style={inputStyle}>
                                    {finalValue}
                                </div>
                            </div>

                        )
                    );
                }
            }

            //stakeholder contacts
            else if (tpt.type === "ContactList"){

                let contactList = this.props.currentObject.object.details.contacts;
                //console.log("data details contact list");
                //console.log(contactList);

                let contacts = [];
                // let fieldLabelStyle = {fontStyle:'italic',fontWeight:500,paddingLeft:10};
                // let fieldContainerStyle = {marginLeft:30};
                // let fieldWidth = 220;
                let rows = [];
                if(contactList !== undefined && contactList !== null) {
                    for (var k = 0; k<contactList.length;k++){
                        let c = contactList[k];
                        rows.push(
                            <tr key={"contact-"+k}>
                                <td>{c.name}</td>
                                <td>{c.position}</td>
                                <td>{c.email}</td>
                                <td>{c.phone}</td>
                                <td>{c.address}</td>
                            </tr>
                        )
                    }
                }
                if(contacts.length === 0){
                    contacts = <div>No Contacts</div>;
                }

                fields.push(

                    <div key={tpt.field}>
                        <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>

                        <table className="table table-responsive">
                            <thead><tr><th>{t("Name")}</th><th>{t("Position")}</th><th>{t("Email")}</th><th>{t("Phone")}</th><th>{t("Address")}</th></tr></thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>

                )

            }
            else if (tpt.type === "StakeholderRegionLink"){

                let objectLists = this.props.currentObject.objectLists;

                if(objectLists !== null && objectLists !== undefined){
                    let objects = objectLists[tpt.type];
                    let link = null;

                    for (var l_id in objects){
                        let l = objects[l_id];
                        if(l.link.left_object_id === obj_id || l.link.right_object_id === obj_id){
                            link = l;
                            break;
                        }
                    }
                    inputClass += " ebase_editor-theme"
                    if(link !== null){
                        value = details.getValue(link,tpt.field);
                        fields.push(
                            <FormGroup key={"readonly_"+tpt.field+"_"+tpt.type} className={fgClass} style={style}>
                                <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                                <div className={inputClass} style={{...inputStyle}}>
                                    <div dangerouslySetInnerHTML={{__html: (value == null) ? t("No Description") : value}}></div>
                                </div>
                            </FormGroup>)
                    }else{
                        fields.push(
                            <FormGroup key={"readonly_"+tpt.field+"_"+tpt.type} className={fgClass} style={style}>
                                <ReadOnlyLabel className={labelClass}>{tpt.display}</ReadOnlyLabel>
                                <div className={inputClass} style={{...inputStyle}}>
                                    <div></div>
                                </div>
                            </FormGroup>

                        );
                    }
                }
            }else if (tpt.type === "ImpactReceptorLinkDescription"){

                // console.log("t.type = ImpactReceptorLinkDescription");
                let objectLists = this.props.currentObject.objectLists;

                let impactTypeInfo = objectLists["ImpactType"];
                let receptorInfo = objectLists["Receptor"];

                let labelTitle = tpt.display;

                if(impactTypeInfo !== null && impactTypeInfo !== undefined
                && receptorInfo !== null && receptorInfo !== undefined){

                    let impactType = impactTypeInfo[Object.keys(impactTypeInfo)[0]];
                    let receptor = receptorInfo[Object.keys(receptorInfo)[0]];

                    labelTitle = impactType.details.displayName + " on " + receptor.details.displayName;
                }

                inputClass += "ebase_editor-theme";

                let field = (
                    <FormGroup key={"readonly_"+tpt.field+"_"+tpt.type} className={fgClass} style={style}>
                        <ReadOnlyLabel className={labelClass}>{labelTitle}</ReadOnlyLabel>
                        <div className={inputClass} style={{...inputStyle}}>

                            <div dangerouslySetInnerHTML={{__html: (value == null) ? t("No Description") : value}}></div>



                        </div>
                    </FormGroup>
                );

                fields.push(field);
            }
        }

        let refs = this.props.currentObject.references;

        let refList = [];
        if (refs != null)
        {
            Object.keys(refs).forEach(refKey => refList.push(<li key={refKey}>{refs[refKey].reference}</li>));
        }

        let references = null;
        if (refList.length > 0)
        {
            references = <ul>{refList}</ul>
        }
        else
        {
            references = <p>{t('None')}</p>
        }


        // now do the references
        references = (
                <div key={"references"}>
                    <ReadOnlyLabel>{t('References')}</ReadOnlyLabel>
                    <div>
                        {references}
                    </div>
                </div>
            )

        let object_type = this.props.currentObject.object.type;
        let object_name = this.props.currentObject.object.details.name;
        if (object_type === "AspectActivityLink")
        {
            object_type = "Aspect";
            object_name = firstValue;
        }
        if(object_type === 'ImpactType' || object_type === "Stakeholder" || object_type === "Project" || object_type === "TechnicalInput" || object_type === "EnvironmentalInput"){
            references = null;
        }
        if(object_type === "Reference"){
            references = null;
            object_name = this.props.currentObject.object.details.reference_info;
        }
        if (object_type === "ProjectActivityLink"){
            object_type = "Activity";
            object_name = firstValue;
        }

        let icon = <h3><EnvidObjectIcon type={object_type} showName={true}/></h3>;

        if(object_type === "ReceptorRegionLink"){
            icon = <h3><EnvidObjectIcon type="Receptor" showName={true}/> in <EnvidObjectIcon type="Region" showName={true}/></h3>;
        }
        if(object_type === "ImpactType"){
            icon = <h3><EnvidObjectIcon type="Impact" showName={true}/></h3>;
        }
        if(object_type === "ImpactReceptorLink"){
            let objectLists = this.props.currentObject.objectLists;
            let receptorInfo = objectLists["Receptor"];
            let receptor = receptorInfo[Object.keys(receptorInfo)[0]];
            icon = <h3><EnvidObjectIcon type="Impact" showName={true}/> on <EnvidObjectIcon type="Receptor" showName={false}/>&nbsp;&nbsp;{receptor.displayName}</h3>;
        }
        return (
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    {icon}
                </div>
                {beforeName == null ? [] : <div className="ibox-content">{beforeName}</div>}
                <div className="ibox-content ibox-heading">
                    <h3>{object_name}</h3>
                </div>
                {!!fields.length &&
                (<div className="ibox-content">
                        {fields}
                    </div>)
                }
                {references !== null &&
                    <div className="ibox-content ibox-heading">
                        {references}
                    </div>
                }
                <div className="ibox-content">
                    &nbsp;
                </div>
            </div>
        )

        // return (
        //     <div style={{backgroundColor:"white", border:"1pt solid #AFAFAF", paddingLeft:25, paddingRight:25, margin:-10}}>
        //         <h2> <EnvidObjectIcon type={this.props.currentObject.object.type}/>&nbsp;&nbsp;&nbsp;{this.props.currentObject.object.details.name}</h2>
        //         <br/>
        //         {fields}
        //
        //     </div>
        // )
    }

}

export default withTranslation()(DataDetailsReadOnly)
