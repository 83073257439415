import React from 'react';
import taskUtil from '../utils/taskUtil'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import {FormGroup, FormControl, ControlLabel, Modal,ModalBody,ModalHeader,ModalTitle,ModalFooter, Table} from 'react-bootstrap'
import RiskAnswerControl from "../components/common/RiskAnswerControl"
import {Trans, useTranslation, withTranslation} from 'react-i18next';
import sitedata from '../utils/sitedata';

function AnswerControl(props) {
    let style = {width:100};

    const {t} = useTranslation();

    //let btns = [{name:"Yes", text:"Yes"}, {name:"No", text:"No"}, {name:"DontKnow", text:"Don't Know"}];
    let btns = [{name:"Yes", text:"Yes"}, {name:"No", text:"No"}];
    return (
        <div className="btn-group">
            {
                btns.map((b) =>  <button key={b.name} style={style}
                                         className={"btn " + ((props.value===b.name)?"btn-primary":"btn-white")}
                                         onDoubleClick={(e) => {e.stopPropagation(); props.onChangedAndNext(b.name);}}
                                         onClick={() => props.onChanged(b.name)} disabled={props.readOnly}>{t(b.text)}</button>)
            }
        </div>

    );
}



function EnvidTree(props) {
    let items = props.items;

    let height = 30;
    let border = "1px solid #DDD";
    let lastArray = [];
    let ret = [];

    for (let i = 0; i < items.length; ++i)
    {
        let item = items[i];
        while (item.level > lastArray.length) lastArray.push(true);
        while (item.level < lastArray.length) lastArray.pop();


        // let isChildren = i + 1 < items.length && item.level == item[i + 1] - 1;
        let isMore = false;
        for (let j = i + 1; !isMore && j < items.length; ++j) {
            isMore = items[j].level === item.level;
        }

        lastArray[item.level] = !isMore;

        let borderHeight = height;
        if (!isMore) {//} && !isChildren) {
            borderHeight = height / 3;
        }

        let zlevel = item.level - 1;
        let borders = [];
        for (let k = 0; k < lastArray.length; ++k) {

            if (!lastArray[k]) {
                borders.push(<div style={{
                    position: "absolute",
                    left: k * 20,
                    top: 0,
                    width: 1,
                    height: height,
                    borderLeft: border
                }}></div>);
            }
        }
        borders.push(<div style={{
            position: "absolute",
            left: zlevel * 20,
            top: 0,
            width: 1,
            height: borderHeight,
            borderLeft: border
        }}></div>);
        if (i === 0) {
            borders.push(<div style={{
                position: "absolute",
                left: zlevel * 20,
                top: -10,
                width: 12,
                height: 10 + height / 3,
                borderBottom: border
            }}></div>);
        } else {
            borders.push(<div style={{
                position: "absolute",
                left: zlevel * 20,
                top: 0,
                width: 12,
                height: height / 3,
                borderBottom: border
            }}></div>);
        }

        ret.push(
            <div>
                <div style={{
                    position: "relative",
                    textAlign: "left",
                    height: height,
                    paddingLeft: 15 + zlevel * 20
                }}>
                    {borders}
                    {item.item}
                </div>
            </div>);
    }

    return <div>{ret}</div>
}



function Head1(props)
{
    return <h2>{props.children}</h2>
}

function Head2(props)
{
    return <h3 style={{marginTop:30}}>{props.children}</h3>
}

function Question(props)
{
    return <h3 style={{fontWeight:"normal"}}>{props.children}</h3>
}

class ReportTaskQuestion extends React.Component {

    constructor() {
        super();
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.state = {showLong:false, showJustification:false};
    }

    updateJustification(answer)
    {
        let params = sitedata.getParams(this.props);
        let changes = {project_id:params.projectId, taskType:params.task};
        changes.answer = "No";
        changes.reason = this.state.justification;
        this.props.updateAnswer(Object.assign({}, answer, changes));
        this.setState({showJustification:false});
    }


    updateAnswerWithJustification(shouldDoNext, answer, v)
    {
        if (v === "No")
        {
            this.setState({showJustification:true, justification:null});
        }
        else
        {
            this.updateAnswer(shouldDoNext, answer, v);
        }

    }

    updateAnswer(shouldDoNext, answer, v)
    {
        // if they answer no to a control question, we have to ask the for a justification
        let params = sitedata.getParams(this.props);
        let changes = {project_id:params.projectId, taskType:params.task};
        changes.answer = v;
        changes.reason = null;

        this.props.updateAnswer(Object.assign({}, answer, changes));
        if (shouldDoNext) {
            this.props.gotoAnswer("NEXT");
        }
    }

    updateRiskAnswer(shouldDoNext, answer, consequnce, likelihood, severity)
    {
        let params = sitedata.getParams(this.props);
        let changes = {project_id:params.projectId, taskType:params.task};
        changes.answer = "Yes";
        changes.likelihood = likelihood;
        changes.consequence = consequnce;
        changes.risk = severity;
        changes.reason = null;

        this.props.updateAnswer(Object.assign({}, answer, changes));
        if (shouldDoNext) {
            this.props.gotoAnswer("NEXT");
        }
    }

    gotoPrevious()
    {
        this.setState({showLong:false, showJustification:false});
        this.props.gotoAnswer("PREV");
    }

    gotoNext() {
        this.setState({showLong:false, showJustification:false});
        this.props.gotoAnswer("NEXT");
    }

    gotoHome() {
        this.setState({showLong:false, showJustification:false});
        this.props.gotoAnswer("HOME");
    }

    render() {

        console.log("Render report task question");
        console.log(this.props);

        const {t} = this.props;

        let taskPosition = this.props.taskPosition;

        let bRisk = this.props.task.object.type === "Risk";

        // find the object we need to show
        let object = taskUtil.getObjectAtPosition(this.props.task, taskPosition);
        let answer = taskUtil.getAnswer(this.props.task, object.object_id);
        let item = taskUtil.getStructureItem(this.props.task, taskPosition);

        console.log("ANSWER ", answer);
        console.log("PROPS ", this.props);

        // we have a different description for every object type
        let object_description = null;
        let question = null;

        let onAnswerClick = this.updateAnswer.bind(this, false, answer);
        let onAnswerDoubleClick = this.updateAnswer.bind(this, true, answer);

        let onRiskAnswerClick = this.updateRiskAnswer.bind(this, false, answer);
        let onRiskAnswerDoubleClick = this.updateRiskAnswer.bind(this, true, answer);

        if (object.type === "Activity") {
            question = <Question>Does this project include the activity <span className="text-navy">{object.details.name}</span>?</Question>

            let project_types = taskUtil.findProjectActivityLinksForActivity(this.props.task, object.object_id);

            let project_types_info = [];

            for (let i in project_types) {
                let pt = project_types[i];

                // find the project
                let project = taskUtil.getObjectFromLink(this.props.task, pt, "ProjectCategory");

                project_types_info.push(
                    <div key={"ptdiv-" + pt.object_id}>
                        <Head2>{t('Link to')+' '}<EnvidObjectIcon type="ProjectCategory"/> &nbsp;{project.details.name}</Head2>
                        <p dangerouslySetInnerHTML={{__html: (pt.details.desc == null) ? t("No Description") : pt.details.desc.text}}/>
                    </div>);
            }

            object_description = (
                <div key={"activity" + object.object_id}>
                    <Head1>{t('About')}: {object.details.name}</Head1>
                    <p dangerouslySetInnerHTML={{__html: (this.state.showLong ? ((object.details.desc == null) ? t("No Description") : object.details.desc.text) : object.details.short_desc)}}/>
                    <p><a onClick={(e) => {e.stopPropagation(); this.setState({showLong:!this.state.showLong})}}>See {this.state.showLong?"less":"more"}</a></p>

                    {project_types_info}

                    <Head2>{t("Aspects associated with this activity")}</Head2>
                    <p>
                        {item.list==null ? <span>{t("No Aspect Link")}</span> : item.list.map((aa, i) => {
                            let aspect = taskUtil.getObject(this.props.task, aa.linkType, aa.link);
                            let comma = (i === 0) ? "" : ", ";
                            if (aspect != null) return <span key={"a1"+aa.link}>{comma}<EnvidObjectIcon
                                type="Aspect"/>&nbsp;{aspect.details.name}</span>
                            else return <span key={"a1"+aa.link}>{comma}<EnvidObjectIcon type="Aspect"/>&nbsp;{t("Empty Object")}</span>
                        })}
                    </p>
                </div>
            );

        } else if (object.type === "AspectActivityLink") {

            let aspect = taskUtil.getObjectFromLink(this.props.task, object, "Aspect");
            let activity = taskUtil.getObjectFromLink(this.props.task, object, "Activity");
            if (activity == null) activity = {details: {desc:t("No Description"), name:t("No Activity")}}

            question = <Question><i>{activity.details.name}</i> {t('can cause')} <span className="text-navy">{aspect.details.name}</span>, {t("do you want to include this in your assessment?")}</Question>


            object_description = (
                <div key={"aspect" + aspect.object_id}>
                    <Head1>{t('About')}: {aspect.details.name}</Head1>
                    <p key={"desc-"+aspect.object_id}  dangerouslySetInnerHTML={{__html:(aspect.details.desc==null)?t("No Description") : aspect.details.desc.text}}/>

                    <Head2>{t('Link to')+' '}<EnvidObjectIcon type="Activity"/> &nbsp;{activity.details.name}</Head2>
                    <p key={"act-"+object.object_id}  dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : object.details.desc.text}}/>
                </div>
            );
        } else if (object.type === "Receptor") {

            let region = taskUtil.getRegion(this.props.task);
            if (region == null) {
                region = {details:{name:t("The Region"), desc: {text:t("Region Description")}}};
            }

            // need the region receptor link.
            const region_name = region.details.name;

            let link = taskUtil.getReceptorRegionLink(this.props.task, object.object_id, region.object_id);
            let are = t("is");
            if (object.details.name.endsWith("s")) {
                are = t("are");
            }
            question = <Question><span className="text-navy">{object.details.name}</span> <Trans i18nKey="question-receptor-location">{{are}} a potential environmental receptor in the <i>{{region_name}}</i>, is it relevant to your activity location and do you want to include this in your assessment?</Trans></Question>

            object_description = (
                <div key={"receptor-"+object.object_id}>
                    <Head1>{t('About')}: {object.details.name}</Head1>
                    <p key={"desc-"+object.object_id} dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : object.details.desc.text}}/>

                    <Head2>{t('Link to')+' '}<EnvidObjectIcon type="Region"/> {region.details.name}</Head2>
                    <p key={"rdesc-"+object.object_id} dangerouslySetInnerHTML={{__html:(link==null || link.details.desc==null)?t("No Description") : link.details.desc.text}}/>
                </div>
            );
        } else if (object.type === "ImpactReceptorLink") {

            let impact = taskUtil.getObjectFromLink(this.props.task, object, "Impact");
            let receptor = taskUtil.getObjectFromLink(this.props.task, object, "Receptor");
            if (impact == null) impact = {details: {desc: {text:t("No Description")}, name:t("No Impact")}}
            if (receptor == null) receptor = {details: {desc: {text:t("No Description")}, name:t("No Receptor")}}

            // let aspect_activity = taskUtil.getObject(this.props.task, "AspectActivityLink", impact.details.aspect_activity_link_id);
            // let aspect = taskUtil.getObjectFromLink(this.props.task, aspect_activity, "Aspect");
            // let activity = taskUtil.getObjectFromLink(this.props.task, aspect_activity, "Activity");
            console.log("Finding Aspect");
            console.log(impact);
            console.log(this.props.task);
            let aspect = taskUtil.getObject(this.props.task, "Aspect", impact.details.aspect_id);
            if (aspect == null) aspect = {details: {desc:t("No Description"), name:t("No Aspect")}}

            let impact_type = taskUtil.getImpactType(this.props.task, impact);

            if (bRisk)
            {
                question =
                <div>
                    <h4 style={{textAlign:"left", fontWeight:"normal", marginBottom:20}}><Trans i18nKey="risk-explanation">Select the risk ranking based on Likelihood (0-5) & Conseqence (A-F) of the Impact</Trans>:</h4>

                    <Question>
                        <span className="text-navy">{impact_type}</span> {t("caused by")}&nbsp;
                        <span className="text-navy">{aspect.details.name}</span> {t("for")}&nbsp;
                        <span className="text-navy">{receptor.details.name}</span>
                    </Question>
                </div>
            }
            else {
                const receptor_name = receptor.details.name;
                const aspect_name = aspect.details.name;
                question =
                    <Question><Trans i18nKey="question-impact-on-receptor"><span className="text-navy">{{impact_type}} on {{receptor_name}}</span> can be caused
                        by <i>{{aspect_name}}</i>, is this relevant to your project?</Trans></Question>
            }

            object_description = (
                <div key={"imrec-"+object.object_id}>
                    <Head1>{t('About')}: {impact.details.name}</Head1>
                    <p key={"desc-"+object.object_id} dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : impact.details.desc.text}}/>

                    <Head2>{t('Link to')+' '}<EnvidObjectIcon type="Receptor" showName={false}/> {receptor.details.name}</Head2>
                    <p key={"lnk-"+object.object_id} dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : object.details.desc.text}}/>

                    <Head2>{t('Links')}</Head2>

                    <EnvidTree items={[
                        {level:1, item:<span><EnvidObjectIcon type="Aspect" showName={false}/> {aspect.details.name}</span>},
                        {level:2, item:<span><EnvidObjectIcon type="Receptor" showName={false}/> {receptor.details.name}</span>},
                        {level:3, item:<span><EnvidObjectIcon type="Impact" showName={false}/> {impact_type}</span>},
                    ]}/>




                </div>
            );
        } else if (object.type === "Control") {

            onAnswerClick = this.updateAnswerWithJustification.bind(this, false, answer);
            onAnswerDoubleClick = this.updateAnswerWithJustification.bind(this, true, answer);

            let control = taskUtil.getObject(this.props.task, "Control", object.object_id );
            if (control == null) control = {details: {desc: {text:t("No Description")}, name:t("No Control")}};
            let impact = taskUtil.getObject(this.props.task, "Impact", control.details.impact_id);
            if (impact == null) impact = {details: {desc: {text:t("No Description")}, name:t("No Impact")}};
            let aspect = taskUtil.getObject(this.props.task, "Aspect", impact.details.aspect_id);
            if (aspect == null) aspect = {details: {desc: {text:t("No Description")}, name:t("No Aspect")}};


            question = <Question>For the impact: <span className="text-navy">{impact.details.name}</span>.
            <br/><br/>Would you like to apply: <span className="text-navy">{taskUtil.getControlName(this.props.task, control)}</span></Question>

            // find all the other controls associated with this guy
            let controls = taskUtil.getOtherControls(this.props.task, impact, control);
            let receptors = taskUtil.getControlsReceptors(this.props.task, control);
            let activites = taskUtil.getControlsActivities(this.props.task, control);

            let tree_items = [
            {level:1, item:<span><EnvidObjectIcon type="Aspect" showName={false}/> {aspect.details.name}</span>},
            {level:2, item:<span><EnvidObjectIcon type="Impact" showName={false}/> {taskUtil.getImpactType(this.props.task, impact)}</span>},
            {level:3, item:<span><EnvidObjectIcon type="Control" showName={false}/> {taskUtil.getControlName(this.props.task, control)}</span>},
            ];

            tree_items = tree_items.concat(activites.map((a) => ({level:4, item:<span><EnvidObjectIcon type="Activity" showName={false}/> {a.details.name}</span>})));
            tree_items = tree_items.concat(receptors.map((a) => ({level:4, item:<span><EnvidObjectIcon type="Receptor" showName={false}/> {a.details.name}</span>})));


        object_description = (
                <div key={"icrec-"+object.object_id}>
                    <Head1>{t('About')}: {taskUtil.getControlName(this.props.task, control)}</Head1>
                    {/*
                    <p><strong>About the Control:</strong> {taskUtil.getControlName(this.props.task, control)}</p>
                    <p><strong>For the Impact:</strong> {impact.details.name}</p>
                    */}
                    <Table>
                        <thead>
                          <tr style={{color:"white", background:"#1ab394", fontWeight:"normal"}}>
                              <th>{t("Control Purpose")}</th>
                              <th>{t("Control Type")}</th>
                              <th>{t("Control Source")}</th>
                              <th>{t("Environmental Performance Outcome")}</th>
                              <th>{t("Environmental Performance Standard")}</th>
                              <th>{t("Measurement Criteria")}</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{border:"1pt dashed #1ab394"}} dangerouslySetInnerHTML={{__html:(control.details.desc==null)?"" : control.details.desc.text}}></td>
                            <td style={{border:"1pt dashed #1ab394"}}>{taskUtil.getControlType(this.props.task, control)}</td>
                            <td style={{border:"1pt dashed #1ab394"}}>{taskUtil.getControlSource(this.props.task, control)}</td>
                            <td style={{border:"1pt dashed #1ab394"}}>{control.details.epo}</td>
                            <td style={{border:"1pt dashed #1ab394"}} dangerouslySetInnerHTML={{__html:(control.details.eps==null)?"" : control.details.eps.text}}></td>
                            <td style={{border:"1pt dashed #1ab394"}} dangerouslySetInnerHTML={{__html:(control.details.criteria==null)?"" : control.details.criteria.text}}></td>
                        </tr>
                        </tbody>
                    </Table>


                    {/*<Head2>Link to <EnvidObjectIcon type="Receptor" showName={false}/> {receptor.details.name}</Head2>*/}
                    {/*<p key={"lnk-"+object.object_id} dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : object.details.desc.text}}/>*/}

                    <Head2>Other Controls associated with this Impact</Head2>
                    {controls.map((c) => <span><EnvidObjectIcon type="Control" showName={false}/> {taskUtil.getControlName(this.props.task, c)}&nbsp;</span>)}
                    {(controls.length === 0) ? t("None") : null}

                    <Head2>Receptors associated with this Control</Head2>
                    {receptors.map((r) => <span><EnvidObjectIcon type="Receptor" showName={false}/> {r.details.name}&nbsp;</span>)}
                    {(receptors.length === 0) ? t("None") : null}

                    <Head2>Activities associated with this Control</Head2>
                    {activites.map((a) => <span><EnvidObjectIcon type="Activity" showName={false}/> {a.details.name}&nbsp;</span>)}
                    {(activites.length === 0) ? t("None") : null}

                    <Head2>{t("Links")}</Head2>
                    <EnvidTree items={tree_items}/>

                 </div>
             );
        } else {
            const object_name = object.details.name;
            question = <Question><Trans i18nKey="question-include-object">Does this project include <span className="text-navy">{object_name}</span>?</Trans></Question>

            object_description = (
                <div key="unknown">
                    <Head1>{t('Type')}: {object.type}</Head1>
                    <Head1>{t('About')}: {object.details.name}</Head1>
                    <p key={"desc-"+object.object_id}  dangerouslySetInnerHTML={{__html:(object.details.desc==null)?t("No Description") : object.details.desc.text}}/>
                </div>
            );
        }

        let answerControl = <AnswerControl value={answer.answer}
                                           onChanged={onAnswerClick}
                                           onChangedAndNext={onAnswerDoubleClick}
                                            readOnly={this.props.readOnly}/>;

        if (bRisk)
        {
            answerControl = <div><RiskAnswerControl value={answer}
                                               matrix={this.props.task.risk_matrix}
                                               onChanged={onRiskAnswerClick}
                                               onChangedAndNext={onRiskAnswerDoubleClick}
                                                readOnly={this.props.readOnly}/>
                                <span className="pull-right" style={{fontSize:"9pt"}}> <a target="_blank" href="/ebase/envid-client/read-report-risk.pdf" download="risk-matrix.pdf"><i className="fa fa-file-o"></i> {t("Download Risk Matrix")}</a></span>
                            </div>
        }

        return (
            <div>
                <Modal show={this.state.showJustification} onHide={() => this.setState({showJustification:false})}>
                    <ModalHeader closeButon>
                        <ModalTitle>
                            {t("Justification")}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>

                        <p>{t("You must supply a justification for selecting no to a control")}</p>
                        <FormGroup>
                            <ControlLabel>{t("Justification")}</ControlLabel>
                            <div>
                                <FormControl componentClass="textarea" placeholder="Justification"
                                             onChange={(e) => this.setState({justification:e.target.value})}
                                             value={this.state.justification}/>
                            </div>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <EButton isTable={true} type="cancel" onClick={() => this.setState({showJustification:false})}>{t("Cancel")}</EButton>
                        <EButton isTable={true} type="save" onClick={this.updateJustification.bind(this, answer)}>{t("Save")}</EButton>
                    </ModalFooter>

                </Modal>


                    <div style={{marginLeft: "7%", marginRight: "7%"}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-content text-center p-md">
                                        {question}
                                        <br/>
                                        {answerControl}
                                        {(answer.reason == null) ? null :
                                            <p><br/><strong>{t("Justification")} : </strong>{answer.reason}</p>}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            height: "55px",
                            marginTop: "-15px",
                            vAlign: "top",
                            paddingTop: 0,
                            position: "relative"
                        }}>
                            <div className="button-menu pull-right"
                                 style={{vAlign: "top", position: "absolute", top: 0, right: 0}}>
                                <EButton type="prev" onClick={this.gotoPrevious}
                                         disabled={taskPosition[0] === 0 && taskPosition.length === 1}>{t("Previous")}</EButton>&nbsp;
                                <EButton type="next" onClick={this.gotoNext}
                                         disabled={answer.answer === "New"}>{t("Next")}</EButton>
                            </div>
                        </div>

                    </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content p-md">
                                {object_description}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}




export default withTranslation()(ReportTaskQuestion)
