import React from 'react';
import sitedata from '../utils/sitedata'
import {Row,Col} from 'react-bootstrap'
import details from '../utils/details'

import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import SplitPane from 'react-split-pane'
import DataDetailsReadOnly from "./DataDetailsReadOnly"
import ControlTree from "../components/common/ControlTree"
import ActionsView from './ActionsView'
import ComplianceSetupView from './ComplianceSetupView'
import {Tab,Tabs} from 'react-bootstrap'

import {ComplianceLabel, ComplianceDueIn} from '../components/common/ComplianceUtils'
import ComplianceFilter from "../utils/ComplianceFilter";
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class ComplianceMgrView extends React.Component {

    constructor()
    {
        super();

        this.selectTab = this.selectTab.bind(this);
    }

    selectTab(tabKey)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let object_id = params.controlId;
        let tabIndex = tabKey;

        let filter = new ComplianceFilter(params.filter);
        SessionManager.shared().goto('/compliance/'+project_id+'/manage/'+filter.toString()+'/'+object_id+'/'+tabIndex);
    }

    selectObject(object)
    {
        let object_id = "none";
        if (object != null)
        {
            object_id = object.object_id;
        }

        let params = sitedata.getParams(this.props);

        let project_id = params.projectId;
        let tabIndex = params.tabIndex;

        let filter = new ComplianceFilter(params.filter);
        SessionManager.shared().goto('/compliance/'+project_id+'/manage/'+filter.toString()+'/'+object_id+'/'+tabIndex);

    }

    onEdit(action_id)
    {
        if (action_id == null)
        {
            this.props.saveFields(this.props.changes);
        }

        this.props.startEdit(action_id);
    }

    saveFields()
    {
        this.props.saveFields(this.props.changes);
    }

    newObject()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let control_id = params.controlId;

        this.props.newComplianceAction(project_id, control_id);

    }

    showAll()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let control_id = params.controlId;
        let tabIndex = params.tabIndex;

        let filter = new ComplianceFilter();
        SessionManager.shared().goto('/compliance/'+project_id+'/manage/'+filter.toString()+'/'+control_id+'/'+tabIndex);
    }

    render() {

        const {t} = this.props;

        // console.log("ComplianceMgrView render");
        // console.log(this.props);
        // console.log("");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let tabIndex = params.tabIndex;
        let controlId = params.controlId;
        let filter = new ComplianceFilter(params.filter);

        // the filter gives us some idea of what to show


        let page_title = t("Compliance Management");
        let crumbs = ['/', '/projects', '/projects/project/'+project_id+'/info', '/compliance/'+project_id+'/home/0', page_title];


        if (this.props.isLoading) {
            return <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)}
                                 crumbs={crumbs}
                                 dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>;
                </PageWrapper>
            </div>
        }

        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        let left_side = <ControlTree selected={controlId}
                                     object={this.props.object}
                                     objectLists={this.props.objectLists}
                                     filter={filter}
                                     user={this.props.siteData.user}
                                     onclick={this.selectObject.bind(this)}
                                     loadBranch={this.props.loadBranch.bind(this)}
        />;
        let selected = <div></div>
        if (controlId === "none") {
            selected = <div>{t("Select a Control")}</div>
        } else if (this.props.isControlLoading || this.props.control == null) {
            selected = <div><Spinner/></div>
        } else {

            let complianceDefn = this.props.complianceDefn;
            let control = this.props.control;//this.props.objectLists["Control"][controlId];
            let control_name_id = control.details.control_name_id;
            let name = this.props.objectLists["ControlName"][control_name_id].details.name;

            let template = details.getObjectReadOnlyTemplate("Control");

            let tabs = [];
            tabs.push(
                <Tab title={t('Compliance')} key="setup" eventKey="setup">
                    <ComplianceSetupView
                        complianceDefn={this.props.complianceDefn}
                        roles={this.props.objectLists["ProjectRole"]}
                        users={this.props.objectLists["User"]}
                        milestones={this.props.objectLists["ProjectMilestone"]}
                        saveFields={this.saveFields.bind(this)}
                        updateField={this.props.updateField}
                    />
                </Tab>);

            let eps = details.getValue(control, "details.eps");
            let eps_panel = null;
            if (this.props.complianceActions != null && this.props.complianceActions.length > 0) {
                eps_panel = (
                            <div style={{border: "1pt solid #CCC", padding: 5, marginBottom: 5}}>
                                <b>{t("Environmental Performance Standard")}</b>
                                <div>
                                    <p dangerouslySetInnerHTML={{__html: (eps == null || eps === "") ? "<p>{t('No Description')}</p>" : eps}}/>
                                </div>
                            </div>
                )
            }

            tabs.push(
                <Tab title={t("Actions")} key="actions" eventKey="actions" disabled={this.props.complianceDefn.details.status == null}>

                    <EbasePanel>
                        <Row>
                            <Col md={10}>
                                {eps_panel}
                            </Col>
                            <Col md={2}>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <EButton type="add" onClick={this.newObject.bind(this)}>{t("New Action")}</EButton></div>
                            </Col>
                        </Row>
                        <ActionsView
                            complianceDefn={this.props.complianceDefn}
                            actions={this.props.complianceActions}
                            users={this.props.objectLists["User"]}
                            editing_id={this.props.editing_id}
                            onEditButton={this.onEdit.bind(this)}
                            saveFields={this.saveFields.bind(this)}
                            updateField={this.props.updateField}
                            uploadEvidence={this.props.uploadEvidence}
                            deleteAction={this.props.deleteAction}
                            deleteEvidence={this.props.deleteEvidence}
                        />
                    </EbasePanel>
                </Tab>);

            tabs.push(
                <Tab title={t('Details')} key="details" eventKey="details">
                    <DataDetailsReadOnly fields={template[0].fields} currentObject={{object:control, objectLists:this.props.objectLists}}/>
                </Tab>);

            selected = <div>

                <Row style={{fontSize:18, marginBottom:10}}>
                    <Col md={7}>
                        <EnvidObjectIcon type="Control"/>&nbsp;&nbsp;{name}
                    </Col>
                    <Col md={2}>
                        <ComplianceLabel status={complianceDefn.details.status}/>
                    </Col>
                    <Col md={3} style={{textAlign:"right"}}>
                        {t("Due:")} <ComplianceDueIn date={complianceDefn.details.next_due_date} status={complianceDefn.details.status}/>
                    </Col>
                </Row>

                <div style={{background:"white", padding:10}}>
                    <div className="tabs-container">
                        <Tabs id="object-tabs" activeKey={tabIndex}
                              onSelect={this.selectTab}>
                            {tabs}
                        </Tabs>
                    </div>
                </div>

            </div>
        }


        let filterMessage = null;
        if (!filter.showAll())
        {
            filterMessage = (
            <div className="alert alert-info" style={{marginBottom: 0}}>
                <Row style={{height: 15}}>
                    <Col md={10} style={{marginTop: -2}}>
                        <i className="fa"></i> <b>{filter.getDisplayMessage()}</b>
                    </Col>
                    <Col md={2} style={{textAlign: "right", marginTop: -7}}>
                        <EButton isTable={true} type="clear" onClick={this.showAll.bind(this)}>{t("Show All")}</EButton>
                    </Col>
                </Row>
            </div>)

        }

        return (
            <div>
            <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={crumbs} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>

                    {filterMessage}

                <SplitPane split="vertical" minSize={350} defaultSize={450}>
                    <div style={pane_style}>
                        {left_side}
                    </div>
                    <div style={pane_style}>
                        {selected}
                    </div>
                </SplitPane>


            </PageWrapper>
        </div>);

    }

}

export default withTranslation()(ComplianceMgrView)
