import React from 'react';
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import EbasePanel from '../components/common/EbasePanel'
import {FormControl} from 'react-bootstrap'
import moment from 'moment';
import EnvidDate from "../utils/EnvidDate";
import {withTranslation} from "react-i18next";

class ProjectMilestone extends React.Component {

    finishedField(fieldName, event){
        this.props.saveFields(this.props.changes);
    }

    inputChanged(object_id, fieldName, event){
        event.preventDefault();
        let value = event.target.value;
        this.props.updateField(object_id, fieldName, value);
    }

    newObject()
    {
        this.props.newObject("ProjectMilestone");
    }

    onEdit(object_id)
    {
        if (object_id == null)
        {
            this.props.saveFields(this.props.changes);
        }

        this.props.startEdit(object_id);
    }

    toDate(dateString)
    {
        if (dateString == null || dateString === "1900-01-01")
        {
            return null;
        }
        return moment(dateString);
    }

    handleDateChange(object_id, fieldName, event)
    {
        let value = event.target.value;

        this.props.updateField(object_id, fieldName, value);
        //
        setTimeout(()=>this.finishedField(fieldName), 500);

    }

    formatDate(dateString)
    {
        if (dateString === "1900-01-01")
        {
            return "Select Date";
        }

        let d = (dateString==null)?moment():moment(dateString);
        return d.format("D MMMM YYYY");
    }
    render(){

        const {t} = this.props;

        if (this.props.isLoading)
        {
            return <Spinner/>
        }

        let addButton = <div style={{display:"flex",justifyContent:"flex-end"}}><EButton type="add" onClick={this.newObject.bind(this)}>{t("New Milestone")}</EButton></div>;

        let milestones = this.props.milestones;
        milestones = milestones.sort((m1,m2) => {
            let d1 = new Date(m1.details.date);
            let d2 = new Date(m2.details.date);
            return d1.getTime() - d2.getTime();
        });

        let milestoneBody = <div style={{textAlign:"center",fontSize:'1.5em'}}>{t("Add your first Milestone")}</div>
        let milestoneInsideBody = null;

        let today_date = moment().format("YYYY-MM-DD");
        let today = (<div key={'today'} className="vertical-timeline-block">
                        <div className="vertical-timeline-icon yellow-bg">
                            <i className="fa fa-check"></i>
                        </div>
                        <div className="vertical-timeline-content">
                            <div className="row">
                                <div className="col-lg-3">
                                                        <span className="vertical-date small text-muted">
                                                            {this.formatDate(null)}
                                                        </span>
                                </div>
                                <div className="col-lg-7">
                                    <span className="right font-bold" style={{fontSize:19}}>{t("Today")}</span>
                                </div>
                            </div>
                        </div>
                    </div>);

        if (this.props.editing_id != null)
        {
            today = null;
        }

            if (milestones != null && milestones.length > 0) {

            milestoneInsideBody = [];

            for (let i = 0; i < milestones.length; ++i)
            {
                let m = milestones[i];

                if (today != null && m.details.date > today_date)
                {
                    milestoneInsideBody.push(today);
                    today = null;
                }

                let date = new EnvidDate(m.details.date, t);

                if (m.details.date == null || m.details.date === "1900-01-01"){
                    let now = moment().format("YYYY-MM-DD");
                    date = new EnvidDate(now, t);
                }



                if (m.object_id === this.props.editing_id)
                {
                    milestoneInsideBody.push(
                        <div key={"m-"+i} className="vertical-timeline-block">
                            <div className="vertical-timeline-icon gray-bg">
                                <i className="fa fa-calendar"></i>
                            </div>
                            <div className="vertical-timeline-content">
                                <div className="row">
                                    <div className="col-lg-4">


                                        <FormControl componentClass="input"
                                                     type="date"
                                                     placeholder={t("Select Date")}
                                                     value={date.format("YYYY-MM-DD")}
                                                     onChange={this.handleDateChange.bind(this, m.object_id, "details.date")}>
                                        </FormControl>

                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl type='text'
                                                     field={"details.name"}
                                                     onClick={(event) => event.target.select()}
                                                     onChange={this.inputChanged.bind(this, m.object_id, "details.name")}
                                                     onBlur={this.finishedField.bind(this, m.object_id, "details.name")}
                                                     onKeyPress={(target) => {if (target.charCode === 13) this.onEdit(null);}}
                                                     style={{width:"100%", fontSize:"13px"}}
                                                     value={m.details.name}/>
                                    </div>
                                    <div className="col-lg-2">
                                        <EButton type="done" isTable={true} onClick={this.onEdit.bind(this, null)}>{t("Done")}</EButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    //<div id="vertical-timeline" className="vertical-container dark-timeline">
                    milestoneInsideBody.push(
                        <div key={"m-"+i} className="vertical-timeline-block">
                            <div className="vertical-timeline-icon gray-bg">
                                <i className="fa fa-calendar"></i>
                            </div>
                            <div className="vertical-timeline-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                            <span className="vertical-date small text-muted">
                                                {this.formatDate(m.details.date)}
                                            </span>
                                    </div>
                                    <div className="col-lg-7">
                                        <span className="right">{m.details.name}</span>
                                    </div>
                                    <div className="col-lg-2">
                                        <EButton type="edit" isTable={true} onClick={this.onEdit.bind(this, m.object_id)}>{t("Edit")}</EButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
            if (today != null)
            {
                milestoneInsideBody.push(today);
                today = null;
            }

        }

        if (milestoneInsideBody != null)
        {
            milestoneBody =  <div id="vertical-timeline" className="vertical-container dark-timeline">
                                {milestoneInsideBody}
                             </div>

        }

        return (
            <EbasePanel>
                {addButton}
                {milestoneBody}

            </EbasePanel>
        );
    }


}

export default withTranslation()(ProjectMilestone);
