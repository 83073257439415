import React from 'react';
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import 'icheck/skins/all.css'
import {Checkbox} from 'react-icheck'
import EnvidPeriod from '../utils/EnvidPeriod'
import EnvidDate from '../utils/EnvidDate'

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ProjectRoleSelect from '../components/common/ProjectRoleSelect'
import {Trans, withTranslation} from "react-i18next";

class ComplianceSetupView extends React.Component {

    finishedField(fieldName, event){
        this.props.saveFields();
    }

    formatDate(date)
    {
        let d = new EnvidDate(date, this.props.t);
        return d.format("D MMM YYYY");

    }

    inputChanged(fieldName, event){
        event.preventDefault();
        let value = event.target.value;

        if (fieldName === "details.due_on_offset")
        {
            value = value + " days";
        }
        else if (fieldName === "details.frequency.count")
        {
            fieldName = "details.frequency";
            let f = new EnvidPeriod(this.props.complianceDefn.details.frequency);
            f.setCount(value);
            value = f.toString();
        }
        this.props.updateField(this.props.complianceDefn.object_id, fieldName, value);
    }

    selectChanged(fieldName, event){
        let value = event.target.value;

        if (fieldName === "details.frequency.type")
        {
            fieldName = "details.frequency";
            let f = new EnvidPeriod(this.props.complianceDefn.details.frequency);
            f.setType(value);
            value = f.toString();
        }

        console.log("CHANGED VALUE ------------ " + value);
        this.props.updateField(this.props.complianceDefn.object_id, fieldName, value);
        setTimeout(this.props.saveFields, 250);
    }

    frequencyCheckChanged(event, value)
    {
        //let value = event.target.value;

        if (value)
        {
            this.props.updateField(this.props.complianceDefn.object_id, "details.frequency", "1 years");
        }
        else
        {
            this.props.updateField(this.props.complianceDefn.object_id, "details.frequency", "ONE_OFF");
        }
        setTimeout(this.props.saveFields, 250);
    }

    render() {

        const {t} = this.props;

        let defn = this.props.complianceDefn;
        // let roles = this.props.roles;
        let users = this.props.users;
        let milestones = this.props.milestones;

        let style = {};
        let fgClass = "";
        let labelClass = "";
        let inputClass = "";
        let inputStyle = {};

        let milestoneList = Object.values(milestones).sort((a, b) => {return a.details.date - b.details.date});

        let due_on_offset = new EnvidPeriod(defn.details.due_on_offset);
        let frequency = new EnvidPeriod(defn.details.frequency);

        let due_date_control = null;
        let initial_due_date_control = null;

        if (defn.details.past_due_dates != null && defn.details.past_due_dates.length>1) {

            if (defn.details.next_due_date != null) {
                due_date_control = (
                    <FormGroup key="details.next_due_date" className={fgClass} style={style}>
                        <ControlLabel className={labelClass}>{t("Next Due Date")}</ControlLabel>
                        <FormControl componentClass="input" placeholder="select" type="date"
                                     style={{width: 200}}
                                     value={defn.details.next_due_date}
                                     onChange={this.selectChanged.bind(this, "details.next_due_date")}>
                        </FormControl>
                    </FormGroup>
                )
            }

            let m = milestones[defn.details.due_on_milestone_id];
            if (m!=null)
            {
                initial_due_date_control = (
                    <FormGroup key="details.due_on_milestone_id" className={fgClass} style={style}>
                        <ControlLabel className={labelClass}>{t("Initial Due Date")}</ControlLabel>
                        <div>
                            <div style={{display:"inline-block", minWidth:400}}>
                                <Trans i18nKey="initial-due-date" values={{name: m.details.name,  date: this.formatDate(m.details.date), days:due_on_offset.getCount()}}
                                       defaults="<para>{{name}} ({{date}}) offset by {{days}} days</para>" components={{para: <p/>}}></Trans>
                            </div>
                        </div>
                    </FormGroup>
                )
            }

        } else {
            due_date_control = (
                <FormGroup key="details.next_due_date" className={fgClass} style={style}>
                    <ControlLabel className={labelClass}>{t("Next Due Date")}</ControlLabel>
                    <div>{this.formatDate(defn.details.next_due_date)}</div>
                </FormGroup>
            )

            initial_due_date_control = (
                <FormGroup key="details.due_on_milestone_id" className={fgClass} style={style}>
                    <ControlLabel className={labelClass}>{t("Initial Due Date")}</ControlLabel>
                    <div>
                        <div style={{display:"inline-block", minWidth:400}}>

                            <FormControl componentClass="select" placeholder="select"
                                         value={defn.details.due_on_milestone_id}
                                         onChange={this.selectChanged.bind(this, "details.due_on_milestone_id")}>
                                {(defn.due_on_milestone_id == null) ? <option>{t("Select")}...</option> : null}
                                {milestoneList.map((m) => {
                                    return <option value={m.object_id}>{m.details.name} ({this.formatDate(m.details.date)})</option>
                                })}

                            </FormControl>
                        </div>
                        <div style={{marginTop:6, display:"inline-block"}}>
                            &nbsp;{t('offset by')}&nbsp;
                        </div>
                        <div style={{display:"inline-block", width:50}}>
                            <FormControl componentClass="input" placeholder="select" type="integer"
                                         value={due_on_offset.getCount()}
                                         onChange={this.inputChanged.bind(this, "details.due_on_offset")}
                                         onBlur={this.finishedField.bind(this, "details.due_on_offset")}>
                            </FormControl>
                        </div>
                        <div style={{marginTop:6, display:"inline-block"}}>
                            &nbsp;{t('days')}
                        </div>
                    </div>
                </FormGroup>
            )

        }

        let frequency_control = <span></span>;
        if (!frequency.isOneOff())
        {
            frequency_control =  (
                <div>
                    <div style={{width:50, display:"inline-block", marginRight:30}}>
                        <FormControl componentClass="input" placeholder="select" type="integer"
                             value={frequency.getCount()}
                             onChange={this.inputChanged.bind(this, "details.frequency.count")}>
                        </FormControl>
                    </div>
                    <div style={{width:100, display:"inline-block"}}>
                        <FormControl componentClass="select" placeholder="select"
                                                     value={frequency.getType()}
                                                     onBlur={this.finishedField.bind(this, "details.frequency")}
                                                     onChange={this.selectChanged.bind(this, "details.frequency.type")}>
                            <option value="days">{t("Days")}</option>
                            <option value="weeks">{t("Weeks")}</option>
                            <option value="months">{t("Months")}</option>
                            <option value="years">{t("Years")}</option>
                        </FormControl>
                    </div>
                </div>);

        }

            return (
            <div>
                <br/>
                <p>{t("Setup your control compliance information")}</p>

                <FormGroup key="details.responsible_role_id" className={fgClass} style={style}>
                    <ControlLabel className={labelClass}>{t("Responsible Role")}</ControlLabel>
                    <div className={inputClass} style={inputStyle}>

                        <ProjectRoleSelect value={defn.details.responsible_role_id}
                                           onChange={this.selectChanged.bind(this, "details.responsible_role_id")}
                                           roles={this.props.roles}
                                           users={users}/>

                    </div>
                </FormGroup>

                {initial_due_date_control}
                {due_date_control}

                <FormGroup key="details.frequency.check" className={fgClass} style={style}>
                    <ControlLabel className={labelClass}>
                        {t("Recurring Compliance Required")}&nbsp;
                        <Checkbox checked={!frequency.isOneOff()}
                                  checkboxClass="icheckbox_square-green"
                                  onChange={this.frequencyCheckChanged.bind(this)}
                                  label=""/>
                    </ControlLabel>
                    <div>
                            {frequency_control}
                    </div>
                </FormGroup>

            </div>
        )


    }
}

export default withTranslation()(ComplianceSetupView)
